import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { GameMode } from '../../global.d';
import { setBrokenGame, setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import ViewContainer from '../components/container';
import {
  MOBILE_TITLE_LOGO_WIDTH,
  MOBILE_TITLE_LOGO_X,
  TITLE_LOGO_HEIGHT,
  TITLE_LOGO_WIDTH,
  TITLE_LOGO_X,
} from '../config';

class GameTitle extends ViewContainer {
  private logo: PIXI.Sprite = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.reelLogo));

  constructor() {
    super();
    this.logo.x = isMobile ? MOBILE_TITLE_LOGO_X : TITLE_LOGO_X;
    this.logo.y = 0;
    this.logo.width = isMobile ? MOBILE_TITLE_LOGO_WIDTH : TITLE_LOGO_WIDTH;
    this.logo.height = TITLE_LOGO_HEIGHT;
    this.visible = !setBrokenGame() || setGameMode() === GameMode.FREE_ROUND_BONUS;
    this.addChild(this.logo);
  }
}

export default GameTitle;
