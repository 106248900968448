import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import ViewContainer from '../../components/container';
import { REELS_AMOUNT, REEL_WIDTH, SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT } from '../../config';

class ReelsBackgroundContainer extends ViewContainer {
  constructor() {
    super();
    this.initBackground();
  }

  initBackground(): void {
    const texture = PIXI.Texture.from(ResourceTypes.reelBackground);
    const sprite = new PIXI.Sprite(texture);
    sprite.width = REELS_AMOUNT * REEL_WIDTH;
    sprite.height = SLOTS_PER_REEL_AMOUNT * SLOT_HEIGHT;
    this.addChild(sprite);
  }
}

export default ReelsBackgroundContainer;
