import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { MAPPED_SYMBOLS, SlotId } from '../../config';
import { EventTypes, Layout } from '../../global.d';
import { setBetAmount, setCoinAmount, setCurrency, setIsMiniPayTable, setSlotLayout } from '../../gql/cache';
import { formatNumber, isScatter, normalizeCoins, showCurrency } from '../../utils';
import {
  PAY_TABLE_BACKGROUND_COLOR,
  PAY_TABLE_HEIGHT,
  PAY_TABLE_WIDTH,
  REELS_AMOUNT,
  REEL_WIDTH,
  SCATTER_SLOT_HEIGHT,
  SCATTER_SLOT_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  SLOT_WIDTH,
  eventManager,
  miniPayTableNumberStyle,
  miniPayTableTextStyle,
} from '../config';
import { Combos, Icon, IconCombo } from '../d';

class MiniPayTable extends PIXI.Container {
  private id: number;

  private isLast: boolean;

  private currency = '';

  private betAmount: number;

  public iconId: SlotId;

  public combos: Combos;

  public rect: PIXI.Graphics;

  public numbers: PIXI.Text;

  public text: PIXI.Text;

  public sprite: PIXI.Sprite;

  constructor(id: number, icon: Icon, combos: Combos) {
    super();
    this.id = id;
    this.isLast = id % REELS_AMOUNT === REELS_AMOUNT - 1;
    // TODO: re-adjustment
    this.x = this.isLast ? -(SLOT_WIDTH * 2 - REEL_WIDTH) : 0;
    this.y = 0;
    this.iconId = icon.id;
    this.width = SLOT_WIDTH * 2;
    this.height = SLOT_HEIGHT;
    this.visible = false;
    this.sortableChildren = true;
    this.combos = _.cloneDeep(combos)?.reverse();
    this.betAmount = setBetAmount();
    eventManager.addListener(EventTypes.SHOW_PAY_TABLE, (i: number) => {
      if (setSlotLayout() === Layout['5x6'] && i < SLOTS_PER_REEL_AMOUNT) {
        return;
      }
      this.showPayTable(i);
    });
    eventManager.addListener(EventTypes.DISABLE_ALL_MINI_PAY_TABLES, () => (this.visible = false));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.visible = false));
    eventManager.addListener(EventTypes.UPDATE_BET, (betAmount: number) => this.handleChangeBetAmount(betAmount));
    // rect
    this.rect = new PIXI.Graphics();
    this.rect.beginFill(PAY_TABLE_BACKGROUND_COLOR);
    this.rect.alpha = 0.75;
    this.rect.drawRoundedRect(
      this.isLast ? -5 : SLOT_WIDTH / 2,
      (SLOT_HEIGHT - PAY_TABLE_HEIGHT) / 2,
      PAY_TABLE_WIDTH,
      PAY_TABLE_HEIGHT,
      15,
    );
    this.rect.zIndex = 2;
    this.addChild(this.rect);
    // sprite
    this.sprite = new PIXI.Sprite();
    this.sprite.anchor.set(0.5, 0.5);
    this.sprite.width = SLOT_WIDTH;
    this.sprite.height = SLOT_HEIGHT;
    this.sprite.zIndex = 3;
    this.addChild(this.sprite);
    this.numbers = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableNumberStyle,
    } as Partial<PIXI.ITextStyle>);
    this.numbers.x = this.isLast ? 20 : SLOT_WIDTH + 10;
    this.numbers.zIndex = 3;
    this.addChild(this.numbers);
    this.text = new PIXI.Text('', {
      wordWrapWidth: SLOT_WIDTH,
      ...miniPayTableTextStyle,
    } as Partial<PIXI.ITextStyle>);
    this.text.x = this.isLast ? 80 : SLOT_WIDTH + 70;
    this.text.zIndex = 3;
    this.addChild(this.text);

    this.currency = setCurrency();
    this.setPayTableData(icon.id, combos);
  }

  setPayTableData(iconId: SlotId, combos: Combos): void {
    this.iconId = iconId;
    this.combos = combos;
    this.sprite.texture = PIXI.Texture.from(MAPPED_SYMBOLS[iconId].default);
    this.sprite.width = isScatter(iconId) ? SCATTER_SLOT_WIDTH : SLOT_WIDTH;
    this.sprite.height = isScatter(iconId) ? SCATTER_SLOT_HEIGHT : SLOT_HEIGHT;
    this.sprite.x = SLOT_WIDTH / 2 + (this.isLast ? SLOT_WIDTH : 0);
    this.sprite.y = SLOT_HEIGHT / 2;
    this.numbers.y = this.calcTextYPosition();
    this.numbers.style.lineHeight = this.calcLineHeight();
    this.numbers.text = this.getCombosNumbers();

    this.text.y = this.calcTextYPosition();
    this.text.style.lineHeight = this.calcLineHeight();
    this.text.text = this.getCombos();
  }

  private calcMultiplier(multiplier: number): number {
    if (isScatter(this.iconId)) {
      return normalizeCoins(setBetAmount() * multiplier);
    }
    return normalizeCoins(setCoinAmount() * multiplier);
  }

  private calcLineHeight(): number {
    if (this.combos && this.combos!.length < 4) return 20;
    return 20;
  }

  private calcTextYPosition(): number {
    if (this.combos && this.combos!.length < 4) return 45;
    return 35;
  }

  private getCombosNumbers(): string {
    return this.combos?.reduce((acc: string, curr: IconCombo) => `${acc} ${curr.pattern}: \n`, '') || '';
  }

  private getCombos(): string {
    return (
      this.combos?.reduce(
        (acc: string, curr: IconCombo) =>
          `${acc} ${formatNumber(this.currency, this.calcMultiplier(curr.multiplier), showCurrency(this.currency))}\n`,
        '',
      ) || ''
    );
  }

  public showPayTable(uniqueId: number): void | undefined {
    if (!setIsMiniPayTable()) return;
    if (uniqueId !== this.id) {
      this.visible = false;
      return;
    }

    this.visible = !this.visible;
  }

  private handleChangeBetAmount(betAmount: number): void {
    this.betAmount = setBetAmount();
    this.text.text = this.getCombos();
  }
}

export default MiniPayTable;
