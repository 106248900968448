import { useQuery, useReactiveVar } from '@apollo/client';
import * as _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Button, Input, RangeSlider } from '@phoenix7dev/shared-components';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setBetAmount, setCoinAmount, setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetsSetting } from '../../gql/fromFragment';
import { getBetAmountGql, getSlotConfigGql, getUserGql } from '../../gql/query';
import { eventManager } from '../../slotMachine/config';
import { countCoins, formatNumber, normalizeCoins, showCurrency, wrap } from '../../utils';

import styles from './betSettings.module.scss';
import { IBetSettingsProps } from './d';

const BetSettings: React.FC<IBetSettingsProps> = (props) => {
  const { className = '', handleClose = () => {} } = props;
  const { data, client } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const betSettings = getBetsSetting();
  const value = data?.betAmount ?? betSettings.minBet;
  const coinMultiplier = setSlotConfig().lineSets[0].coinAmountMultiplier;
  const {
    user: { balance },
  } = client.readQuery({
    query: getUserGql,
  });

  const changeBet = (v: number) => {
    if (betSettings.maxBet === v) {
      AudioHowl.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
    setCoinAmount(v);
    setBetAmount(v * coinMultiplier);
    eventManager.emit(EventTypes.UPDATE_BET);
  };

  const changeSliderBet = (v: number) => {
    if (betSettings.maxBet === betSettings.bets[v - 1]) {
      AudioHowl.play({ type: ISongs.SFX_UI_MaxBet });
    } else {
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
    setCoinAmount(betSettings.bets[v - 1]);
    setBetAmount(coinMultiplier * (betSettings.bets[v - 1] || 0));
    eventManager.emit(EventTypes.UPDATE_BET);
  };

  return (
    <div className={`${styles['bet-settings']} ${className}`}>
      <Input
        className={`input ${styles.input}`}
        type="text"
        value={formatNumber(
          balance.currency,
          normalizeCoins(countCoins({ totalAmount: data?.betAmount })),
          showCurrency(balance.currency),
        )}
        onChange={() => {}}
        readOnly
      />
      <RangeSlider
        className={`range-slider ${styles['range-slider']}`}
        min={1}
        max={betSettings.bets.length}
        value={_.findIndex(betSettings.bets, (bet) => bet === value / coinMultiplier) + 1}
        onChange={changeSliderBet}
      />
      <div className={styles['fast-bet']}>
        {betSettings.quick.map((item) => (
          <div key={item} className={styles['fast-bet__item']}>
            <Button
              intent="betSettingsBtn"
              className={styles.btn}
              isActive={value === item * coinMultiplier}
              title={formatNumber(
                balance.currency,
                countCoins({
                  coinAmount: item,
                  coinValue: setCoinValue(),
                  lines: coinMultiplier,
                }),
                true,
              )}
              onClick={wrap(changeBet, item)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BetSettings;
