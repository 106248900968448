import * as PIXI from 'pixi.js';

// const sample = new PIXI.TextStyle({
//   align: 'center', // string;
//   breakWords: true, // ##false, // boolean;
//   dropShadow: true, // ##false, // boolean;
//   dropShadowAlpha: 0.75, // ##1, // number;
//   dropShadowAngle: 0.785, // ##Math.PI / 6, // number;
//   dropShadowBlur: 10, // ##0, // number;
//   dropShadowColor: 0x000000, // ##'black', // string | number;
//   dropShadowDistance: 4, // number;
//   fill: [0xffc000, 0xff0000], // ##'black', // string | string[] | number | number[] | CanvasGradient | CanvasPattern;
//   fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL, // number;
//   // fillGradientStops: [0,1], //number[];
//   fontFamily: 'NotoSans-SemiCondensedBold', // string | string[];
//   fontSize: 80, // number | string;
//   fontStyle: 'normal', // string;
//   fontVariant: 'normal', // string;
//   fontWeight: 'bold', // ## 'normal', // string;
//   leading: 0, // number;
//   letterSpacing: 0, // number;
//   // lineHeight: 0, //number;
//   lineJoin: 'round', // ##'miter', // string;
//   miterLimit: 10, // number;
//   padding: 4, // ##0, // number;
//   stroke: '#ffffff', // ##'black', // string | number;
//   strokeThickness: 6, // ##0, // number;
//   trim: false, // boolean;
//   textBaseline: 'alphabetic', // string;
//   whiteSpace: 'pre', // string;
//   wordWrap: false, // boolean;
//   wordWrapWidth: 1330, // number;
// });

export const multiplierTitleTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  dropShadowAngle: 1.2,
  // fill: ['#a6925c', '#c8b279', '#ab9363', '#a08b54'],
  // fillGradientStops: [0, 0.4, 0.48, 0.59, 1],
  fill: [
    // '#f08418',
    '#9f6518',
    '#ffeb19',
    '#FBC41C',
    '#F5FF3C',
    '#f08418',
  ],
  fillGradientStops: [0.1, 0.31, 0.42, 0.43, 0.58, 0.65, 0.72, 0.75, 0.8],
  fontSize: 70,
  fontWeight: 'bolder',
  stroke: '#000000',
  strokeThickness: 6,
});

const winMessage = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 140,
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'bold',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

const winCounterStyle = new PIXI.TextStyle({
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: [0xffc000, 0xff0000],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 50,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#ffffff',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
});

export const miniPayTableNumberStyle = {
  fontSize: 16,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xffffff',
};

export const miniPayTableTextStyle = {
  fontSize: 16,
  fontFamily: 'NotoSans-SemiCondensedBold',
  whiteSpace: 'normal',
  fill: '0xf6e87e',
};

export const bottomContainerTextStyle = {
  fontFamily: 'NotoSans-SemiCondensedBold',
  fill: '#f6e87e',
  dropShadow: true,
  dropShadowAngle: 0.75,
  dropShadowBlur: 3,
  dropShadowColor: '#9b511b',
  dropShadowDistance: 2,
};

export const bigWinStyles = new PIXI.TextStyle(winMessage);
export const megaWinStyles = new PIXI.TextStyle(winMessage);
export const greatWinStyles = new PIXI.TextStyle(winMessage);
export const epicWinStyles = new PIXI.TextStyle(winMessage);
export const winValueStyles = new PIXI.TextStyle(winCounterStyle);

// BuyFeature Button Text
export const FEATURE_BTN_TEXT_FIT_TO_WIDTH = 160;
export const FEATURE_BTN_TEXT_X_OFFSET = 0;
export const FEATURE_BTN_TEXT_Y_OFFSET = 2;
export const FEATURE_BTN_TEXT_PRESS_X_OFFSET = FEATURE_BTN_TEXT_X_OFFSET + 1;
export const FEATURE_BTN_TEXT_PRESS_Y_OFFSET = FEATURE_BTN_TEXT_Y_OFFSET + 1;
export const buyFeatureTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 30,
  lineJoin: 'round',
  fill: ['#ffbe4e'],
});
export const buyFeatureHoverTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  dropShadow: true,
  dropShadowColor: '#505050',
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 30,
  lineJoin: 'round',
  fill: ['#ffffff'],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
});
export const buyFeatureClickTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  align: 'center',
  fontSize: 25,
  lineHeight: 25,
  lineJoin: 'round',
  fill: ['rgba(255, 255, 255, 0.5)'],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
});

export const buyFeatureDisableTextStyle = new PIXI.TextStyle({
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontWeight: 'bold',
  dropShadow: true,
  align: 'center',
  dropShadowDistance: 4,
  dropShadowAngle: 1,
  fontSize: 30,
  lineHeight: 30,
  lineJoin: 'round',
  fill: ['rgba(255, 190, 78, 0.5)'],
  fillGradientStops: [0.2, 0.38, 0.5, 0.51, 0.53, 0.59, 0.66, 0.78, 0.8],
});

export const sliderValueTextStyle = { fontSize: 14, fill: 0xeaeaea };
