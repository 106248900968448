import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { EventTypes, MessageBannerProps } from '../../global.d';
import { ResourceTypes } from '../../resources.d';
import { updateTextScale } from '../../utils';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  BANNER_HEIGHT,
  BANNER_POSITION_X,
  BANNER_POSITION_Y,
  BANNER_WIDTH,
  BUTTON_POSITION_X,
  BUTTON_POSITION_Y,
  INIT_SUBTEXT_POSITION_Y,
  INIT_TEXT_POSITION_Y,
  MOBILE_BUTTON_POSITION_X,
  MOBILE_BUTTON_POSITION_Y,
  MOBILE_TEXT_POSITION_X,
  MOBILE_WIN_BANNER_WIDTH,
  MOBILE_WIN_BANNER_X,
  SUBTEXT_POSITION_X,
  TEXT_POSITION_X,
} from './config';
import { btnStyle, mobileBtnStyle } from './textStyles';

interface IOptions {
  text?: string;
  position?: number;
  styles?: PIXI.TextStyle;
  borderHeight?: number;
  borderWidth?: number;
}

class MessageBanner extends ViewContainer {
  protected banner: PIXI.Sprite;

  protected title: PIXI.Text;

  protected subtitle: PIXI.Text;

  protected btn: PIXI.Text;

  protected bindedHandleDestroy = this.handleDestroy.bind(this);

  constructor(props: MessageBannerProps) {
    super();
    this.x = BANNER_POSITION_X;
    this.y = BANNER_POSITION_Y;
    this.visible = true;
    this.interactive = true;
    this.on('click', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.on('touchstart', () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    eventManager.addListener(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER, () => {
      if (props.callback) {
        props.callback();
        props.callback = undefined;
      }
      if (!props.preventDefaultDestroy) this.handleDestroy();
    });
    this.banner = this.initBanner();
    this.title = this.initText({
      text: props.title,
      position: isMobile ? props.mobileTitlePosition : props.titlePosition,
      styles: isMobile ? props.mobileTitleStyle : props.titleStyles,
    });

    this.subtitle = this.initSubText({
      text: props.subtitle,
      position: isMobile ? props.mobileSubtitlePosition : props.subtitlePosition,
      styles: isMobile ? props.mobileSubtitleStyle : props.subtitleStyles,
      borderWidth: isMobile ? props.mobileSubtitleBorderWidth : props.subtitleBorderWidth,
      borderHeight: isMobile ? props.mobileSubtitleBorderHeight : props.subtitleBorderHeight,
    });
    this.btn = this.initBtn(props.btnText);
    eventManager.addListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    this.init();
    if (props.onInitCallback) {
      props.onInitCallback();
    }
  }

  protected init(): void {
    this.addChild(this.banner);
    this.addChild(this.title);
    this.addChild(this.subtitle);
    this.addChild(this.btn);
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.messageBanner));
    banner.width = isMobile ? MOBILE_WIN_BANNER_WIDTH : BANNER_WIDTH;
    banner.height = BANNER_HEIGHT;
    banner.x = isMobile ? MOBILE_WIN_BANNER_X : 0;
    return banner;
  }

  protected initText(options: IOptions): PIXI.Text {
    const text = new PIXI.Text(i18n.t(options.text || ''), options.styles);
    text.y = options.position || INIT_TEXT_POSITION_Y;
    text.x = isMobile ? MOBILE_TEXT_POSITION_X : TEXT_POSITION_X;
    text.visible = !!options.text;
    updateTextScale(text, isMobile ? 850 : 1000, isMobile ? 350 : 250);
    text.anchor.set(0.5, 0.5);

    return text;
  }

  protected initSubText(options: IOptions): PIXI.Text {
    const subtext = new PIXI.Text(i18n.t(options.text || ''), options.styles);
    subtext.y = options.position || INIT_SUBTEXT_POSITION_Y;
    subtext.x = SUBTEXT_POSITION_X;
    subtext.visible = !!options.text;
    updateTextScale(subtext, isMobile ? 350 : 600, isMobile ? 450 : 250);
    // updateTextScale(
    //   subtext,
    //   options.borderWidth ? options.borderWidth : 350,
    //   options.borderHeight ? options.borderHeight : 450,
    // );
    subtext.anchor.set(0.5, 0.5);

    return subtext;
  }

  protected initBtn(btnText?: string): PIXI.Text {
    const btn = new PIXI.Text(i18n.t(btnText || ''), isMobile ? mobileBtnStyle : btnStyle);
    btn.y = isMobile ? MOBILE_BUTTON_POSITION_Y : BUTTON_POSITION_Y;
    btn.x = isMobile ? MOBILE_BUTTON_POSITION_X : BUTTON_POSITION_X;
    btn.visible = !!btnText;
    updateTextScale(btn, isMobile ? 516 : 400, isMobile ? 100 : 77);
    btn.anchor.set(0.5, 0.5);

    return btn;
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.MANUAL_DESTROY_MESSAGE_BANNER, this.bindedHandleDestroy);
    eventManager.removeAllListeners(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
    this.destroy({ children: true, texture: false, baseTexture: false });
  }
}

export default MessageBanner;
