import { ResourceTypes } from '../resources.d';
import Resources from '../utils/resources';

import { SlotId } from './config';

export const MAPPED_SYMBOLS: Record<SlotId, { default: ResourceTypes; freespin?: ResourceTypes }> = {
  [SlotId.A]: { default: ResourceTypes.a },
  [SlotId.B]: { default: ResourceTypes.b },
  [SlotId.C]: { default: ResourceTypes.c },
  [SlotId.D]: { default: ResourceTypes.d },
  [SlotId.E]: { default: ResourceTypes.e },
  [SlotId.F]: { default: ResourceTypes.f },
  [SlotId.G]: { default: ResourceTypes.g },
  [SlotId.H]: { default: ResourceTypes.h },
  [SlotId.I]: { default: ResourceTypes.i },
  [SlotId.SC1]: { default: ResourceTypes.cS1 },
  [SlotId.SC2]: { default: ResourceTypes.cS2 },
  [SlotId.SC3]: { default: ResourceTypes.cS1 },
  [SlotId.SC4]: { default: ResourceTypes.cS2 },
  [SlotId.SC5]: { default: ResourceTypes.cS1 },
  [SlotId.SC6]: { default: ResourceTypes.cS2 },
  [SlotId.SC7]: { default: ResourceTypes.cS1 },
  [SlotId.SC8]: { default: ResourceTypes.cS2 },
};

export enum SymbolAnimationType {
  DEFAULT,
  SPRITE,
  SPINE,
}

export const MAPPED_SYMBOLS_WIN_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'a_symbol_win',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'b_symbol_win',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'c_symbol_win',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'd_symbol_win',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'e_symbol_win',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'f_symbol',
    animation: 'f_symbol_win',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'g_symbol',
    animation: 'g_symbol_win',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'h_symbol',
    animation: 'h_symbol_win',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'i_symbol',
    animation: 'i_symbol_win',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_win',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_win',
  },
  [SlotId.SC3]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_win',
  },
  [SlotId.SC4]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_win',
  },
  [SlotId.SC5]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_win',
  },
  [SlotId.SC6]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_win',
  },
  [SlotId.SC7]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_win',
  },
  [SlotId.SC8]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_win',
  },
};

export const MAPPED_SYMBOLS_LAND_ANIMATIONS: {
  [key in SlotId]: {
    type: SymbolAnimationType;
    src?: string;
    animation?: string;
  };
} = {
  [SlotId.A]: {
    type: SymbolAnimationType.SPINE,
    src: 'a_symbol',
    animation: 'a_symbol_land',
  },
  [SlotId.B]: {
    type: SymbolAnimationType.SPINE,
    src: 'b_symbol',
    animation: 'b_symbol_land',
  },
  [SlotId.C]: {
    type: SymbolAnimationType.SPINE,
    src: 'c_symbol',
    animation: 'c_symbol_land',
  },
  [SlotId.D]: {
    type: SymbolAnimationType.SPINE,
    src: 'd_symbol',
    animation: 'd_symbol_land',
  },
  [SlotId.E]: {
    type: SymbolAnimationType.SPINE,
    src: 'e_symbol',
    animation: 'e_symbol_land',
  },
  [SlotId.F]: {
    type: SymbolAnimationType.SPINE,
    src: 'f_symbol',
    animation: 'f_symbol_land',
  },
  [SlotId.G]: {
    type: SymbolAnimationType.SPINE,
    src: 'g_symbol',
    animation: 'g_symbol_land',
  },
  [SlotId.H]: {
    type: SymbolAnimationType.SPINE,
    src: 'h_symbol',
    animation: 'h_symbol_land',
  },
  [SlotId.I]: {
    type: SymbolAnimationType.SPINE,
    src: 'i_symbol',
    animation: 'i_symbol_land',
  },
  [SlotId.SC1]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_land',
  },
  [SlotId.SC2]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_land',
  },
  [SlotId.SC3]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_land',
  },
  [SlotId.SC4]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_land',
  },
  [SlotId.SC5]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_land',
  },
  [SlotId.SC6]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_land',
  },
  [SlotId.SC7]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_angel',
    animation: 'scatter_angel_land',
  },
  [SlotId.SC8]: {
    type: SymbolAnimationType.SPINE,
    src: 'scatter_devil',
    animation: 'scatter_devil_land',
  },
};
export const LOADER_MAPPED_SYMBOLS = [
  { name: ResourceTypes.a, src: Resources.getSource(ResourceTypes.a) },
  { name: ResourceTypes.b, src: Resources.getSource(ResourceTypes.b) },
  {
    name: ResourceTypes.c,
    src: Resources.getSource(ResourceTypes.c),
  },
  {
    name: ResourceTypes.d,
    src: Resources.getSource(ResourceTypes.d),
  },
  { name: ResourceTypes.e, src: Resources.getSource(ResourceTypes.e) },
  {
    name: ResourceTypes.f,
    src: Resources.getSource(ResourceTypes.f),
  },
  {
    name: ResourceTypes.g,
    src: Resources.getSource(ResourceTypes.g),
  },
  {
    name: ResourceTypes.h,
    src: Resources.getSource(ResourceTypes.h),
  },
  {
    name: ResourceTypes.i,
    src: Resources.getSource(ResourceTypes.i),
  },
  {
    name: ResourceTypes.cS1,
    src: Resources.getSource(ResourceTypes.cS1),
  },
  {
    name: ResourceTypes.cS2,
    src: Resources.getSource(ResourceTypes.cS2),
  },
  {
    name: ResourceTypes.lock,
    src: Resources.getSource(ResourceTypes.lock),
  },
];

export const LOADER_TEXTURES = [
  { name: ResourceTypes.frame, src: Resources.getSource(ResourceTypes.frame) },
  {
    name: ResourceTypes.frameMobile,
    src: Resources.getSource(ResourceTypes.frameMobile),
  },
  {
    name: ResourceTypes.shortLine,
    src: Resources.getSource(ResourceTypes.shortLine),
  },
  {
    name: ResourceTypes.mediumLine,
    src: Resources.getSource(ResourceTypes.mediumLine),
  },
  {
    name: ResourceTypes.largeLine,
    src: Resources.getSource(ResourceTypes.largeLine),
  },
  {
    name: ResourceTypes.reelBackground,
    src: Resources.getSource(ResourceTypes.reelBackground),
  },
  {
    name: ResourceTypes.messageBanner,
    src: Resources.getSource(ResourceTypes.messageBanner),
  },
  {
    name: 'coinsAnimation',
    src: '/animations/coins/coins.json',
  },
  {
    name: 'a_symbol',
    src: '/animations/symbol_win/a_symbol.json',
  },
  {
    name: 'b_symbol',
    src: '/animations/symbol_win/b_symbol.json',
  },
  {
    name: 'c_symbol',
    src: '/animations/symbol_win/c_symbol.json',
  },
  {
    name: 'd_symbol',
    src: '/animations/symbol_win/d_symbol.json',
  },
  {
    name: 'e_symbol',
    src: '/animations/symbol_win/e_symbol.json',
  },
  {
    name: 'f_symbol',
    src: '/animations/symbol_win/f_symbol.json',
  },
  {
    name: 'g_symbol',
    src: '/animations/symbol_win/g_symbol.json',
  },
  {
    name: 'h_symbol',
    src: '/animations/symbol_win/h_symbol.json',
  },
  {
    name: 'i_symbol',
    src: '/animations/symbol_win/i_symbol.json',
  },
  {
    name: 'scatter_angel',
    src: '/animations/symbol_win/scatter_angel.json',
  },
  {
    name: 'scatter_devil',
    src: '/animations/symbol_win/scatter_devil.json',
  },
  {
    name: 'explosion',
    src: '/animations/symbol_win/explosion.json',
  },
  {
    name: 'ambient',
    src: '/animations/ambient/ambient.json',
  },
  {
    name: 'locks',
    src: '/animations/fs_effects/lock_symbol.json',
  },
  {
    name: 'multiplierExplosion',
    src: '/animations/multiplier_explosion/explosion.json',
  },
  {
    name: 'multiplierTrail',
    src: '/animations/multiplier_trail/explosion_trail.json',
  },
  {
    name: 'angel_attack',
    src: '/animations/fs_effects/angel_attack.json',
  },
  {
    name: 'devil_attack',
    src: '/animations/fs_effects/devil_attack.json',
  },
  {
    name: 'characters',
    src: '/animations/characters/characters.json',
  },
  {
    name: 'buttonsSprite',
    src: '/images/buttons/buttons.json',
  },
  {
    name: 'infoBtnSprite',
    src: '/images/infoBtn/infomation.json',
  },
  {
    name: 'loaderSprite',
    src: '/images/loader/loader.json',
  },
  {
    name: ResourceTypes.reelLogo,
    src: Resources.getSource(ResourceTypes.reelLogo),
  },
  {
    name: ResourceTypes.slotBg,
    src: Resources.getSource(ResourceTypes.slotBg),
  },
  {
    name: ResourceTypes.introLogo,
    src: Resources.getSource(ResourceTypes.introLogo),
  },
  {
    name: ResourceTypes.introBg,
    src: Resources.getSource(ResourceTypes.introBg),
  },
  {
    name: ResourceTypes.multipliersLock,
    src: Resources.getSource(ResourceTypes.multipliersLock),
  },
  {
    name: ResourceTypes.introReel1,
    src: Resources.getSource(ResourceTypes.introReel1),
  },
  {
    name: ResourceTypes.introReel2,
    src: Resources.getSource(ResourceTypes.introReel2),
  },
  {
    name: ResourceTypes.introReel3,
    src: Resources.getSource(ResourceTypes.introReel3),
  },
  {
    name: ResourceTypes.buttonOk,
    src: Resources.getSource(ResourceTypes.buttonOk),
  },
  {
    name: ResourceTypes.slotBgAngel,
    src: Resources.getSource(ResourceTypes.slotBgAngel),
  },
  {
    name: ResourceTypes.slotBgDevil,
    src: Resources.getSource(ResourceTypes.slotBgDevil),
  },
  {
    name: ResourceTypes.slotBgAngelAndDevil,
    src: Resources.getSource(ResourceTypes.slotBgAngelAndDevil),
  },
  {
    name: ResourceTypes.buyFeatureBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureBtn),
  },
  {
    name: ResourceTypes.buyFeatureBtnNotActive,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnNotActive),
  },
  {
    name: ResourceTypes.buyFeatureBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureInput,
    src: Resources.getSource(ResourceTypes.buyFeatureInput),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtn),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureMinusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureMinusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtn,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtn),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnHover),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnPressed),
  },
  {
    name: ResourceTypes.buyFeaturePlusBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeaturePlusBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureOkBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtn),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureOkBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureOkBtnDisable),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtn,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtn),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnHover,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnHover),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnPressed,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnPressed),
  },
  {
    name: ResourceTypes.buyFeatureCancelBtnDisable,
    src: Resources.getSource(ResourceTypes.buyFeatureCancelBtnDisable),
  },
  {
    name: ResourceTypes.buyFeaturePopup,
    src: Resources.getSource(ResourceTypes.buyFeaturePopup),
  },
  {
    name: ResourceTypes.backdrop,
    src: Resources.getSource(ResourceTypes.backdrop),
  },
  {
    name: ResourceTypes.buyFeaturePopupConfirm,
    src: Resources.getSource(ResourceTypes.buyFeaturePopupConfirm),
  },
  {
    name: ResourceTypes.angel,
    src: Resources.getSource(ResourceTypes.angel),
  },
  {
    name: ResourceTypes.angelActive,
    src: Resources.getSource(ResourceTypes.angelActive),
  },
  {
    name: ResourceTypes.angelHover,
    src: Resources.getSource(ResourceTypes.angelHover),
  },
  {
    name: ResourceTypes.angelAndDevil,
    src: Resources.getSource(ResourceTypes.angelAndDevil),
  },
  {
    name: ResourceTypes.angelAndDevilActive,
    src: Resources.getSource(ResourceTypes.angelAndDevilActive),
  },
  {
    name: ResourceTypes.angelAndDevilHover,
    src: Resources.getSource(ResourceTypes.angelAndDevilHover),
  },
  {
    name: ResourceTypes.devil,
    src: Resources.getSource(ResourceTypes.devil),
  },
  {
    name: ResourceTypes.devilActive,
    src: Resources.getSource(ResourceTypes.devilActive),
  },
  {
    name: ResourceTypes.devilHover,
    src: Resources.getSource(ResourceTypes.devilHover),
  },
  {
    name: ResourceTypes.confirmAngelBg,
    src: Resources.getSource(ResourceTypes.confirmAngelBg),
  },
  {
    name: ResourceTypes.confirmAngelAndDevilBg,
    src: Resources.getSource(ResourceTypes.confirmAngelAndDevilBg),
  },
  {
    name: ResourceTypes.confirmDevilBg,
    src: Resources.getSource(ResourceTypes.confirmDevilBg),
  },
  {
    name: ResourceTypes.featureButtonBg,
    src: Resources.getSource(ResourceTypes.featureButtonBg),
  },
  {
    name: ResourceTypes.angelDevilMultiplayerFrame,
    src: Resources.getSource(ResourceTypes.angelDevilMultiplayerFrame),
  },
  {
    name: ResourceTypes.angelDevilMultiplayerIcon,
    src: Resources.getSource(ResourceTypes.angelDevilMultiplayerIcon),
  },
  {
    name: ResourceTypes.angelMultiplayerFrame,
    src: Resources.getSource(ResourceTypes.angelMultiplayerFrame),
  },
  {
    name: ResourceTypes.angelMultiplayerIcon,
    src: Resources.getSource(ResourceTypes.angelMultiplayerIcon),
  },
  {
    name: ResourceTypes.devilMultiplayerIcon,
    src: Resources.getSource(ResourceTypes.devilMultiplayerIcon),
  },
  {
    name: ResourceTypes.devilMultiplayerFrame,
    src: Resources.getSource(ResourceTypes.devilMultiplayerFrame),
  },
  {
    name: ResourceTypes.freeSpinBannerAngel,
    src: Resources.getSource(ResourceTypes.freeSpinBannerAngel),
  },
  {
    name: ResourceTypes.freeSpinBannerDevil,
    src: Resources.getSource(ResourceTypes.freeSpinBannerDevil),
  },
];
