import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, GameMode } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setGameMode, setPrevGameMode } from '../../gql/cache';
import { delayedAction, getGameModeByBonusId, isFreeRoundBonusMode, isFreeSpinMode, isRegularMode } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { CHARACTERS_FEATURE_WIN_DURATION, eventManager } from '../config';

export class CharactersContainer extends ViewContainer {
  private angelAndDevilSkinSpine: SpineAnimation;

  private dummy: PIXI.Sprite;

  private animationIdleNames = {
    [GameMode.REGULAR]: 'angel_devil_idle',
    [GameMode.ANGEL]: 'angel_feature_idle',
    [GameMode.DEVIL]: 'devil_feature_idle',
    [GameMode.ANGEL_AND_DEVIL]: 'angel_devil_feature_idle',
  };

  constructor() {
    super();
    this.angelAndDevilSkinSpine = new SpineAnimation({}, PIXI.Loader.shared.resources.characters.spineData!);
    this.dummy = new PIXI.Sprite(PIXI.Texture.WHITE);
    this.dummy.width = 1920;
    this.dummy.height = 1080;
    this.dummy.visible = false;
    this.dummy.anchor.set(0.5);
    if (setBrokenGame() && !isFreeRoundBonusMode(setGameMode())) {
      const mode = getGameModeByBonusId(setCurrentBonus().bonusId);
      const animationName = this.animationIdleNames[mode];
      this.angelAndDevilSkinSpine.setAnimation(animationName, true);
    } else {
      this.angelAndDevilSkinSpine.setAnimation(this.animationIdleNames[GameMode.REGULAR], true);
    }
    this.addChild(this.dummy);
    this.addChild(this.angelAndDevilSkinSpine.spine);
    eventManager.addListener(EventTypes.START_CHARACTER_ANIMATION, this.startCharacterAnimation.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onChangeMode.bind(this));
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;
    const bgAspectRatio = this.dummy.width / this.dummy.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.dummy.height);
    } else {
      this.scale.set(width / this.dummy.width);
    }
  }

  private onChangeMode(settings: { mode: GameMode }): void {
    if (isRegularMode(settings.mode)) {
      this.angelAndDevilSkinSpine.setAnimation(this.animationIdleNames[GameMode.REGULAR], true);
    }
  }

  private startCharacterAnimation(mode: GameMode): void {
    if (mode === GameMode.ANGEL) {
      delayedAction(
        CHARACTERS_FEATURE_WIN_DURATION,
        () => {
          this.angelAndDevilSkinSpine.setAnimation(this.animationIdleNames[GameMode.ANGEL], true);
        },
        () => {
          AudioHowl.play({ type: ISongs.Angel_Disp, stopPrev: true });
          this.angelAndDevilSkinSpine.setAnimation('angel_feature_triggered', false);
        },
      );
    }

    if (mode === GameMode.DEVIL) {
      delayedAction(
        CHARACTERS_FEATURE_WIN_DURATION,
        () => {
          this.angelAndDevilSkinSpine.setAnimation(this.animationIdleNames[GameMode.DEVIL], true);
        },
        () => {
          AudioHowl.play({ type: ISongs.Devil_Disp, stopPrev: true });

          this.angelAndDevilSkinSpine.setAnimation('devil_feature_triggered', false);
        },
      );
    }

    if (mode === GameMode.ANGEL_AND_DEVIL) {
      const animationNames = {
        [GameMode.REGULAR]: 'angel_devil_feature_triggered',
        [GameMode.ANGEL]: 'angel_devil_feature_triggered_3',
        [GameMode.DEVIL]: 'angel_devil_feature_triggered_2',
      };
      delayedAction(
        CHARACTERS_FEATURE_WIN_DURATION,
        () => {
          this.angelAndDevilSkinSpine.setAnimation(this.animationIdleNames[GameMode.ANGEL_AND_DEVIL], true);
        },
        () => {
          AudioHowl.play({ type: ISongs.Angel_Disp, stopPrev: true });
          AudioHowl.play({ type: ISongs.Devil_Disp, stopPrev: true });
          this.angelAndDevilSkinSpine.setAnimation(
            animationNames[setGameMode()] || animationNames[GameMode.REGULAR],
            false,
          );
        },
      );
    }
  }
}
