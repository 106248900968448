export enum ResourceTypes {
  angelAndDevilActive = 'angelAndDevilActive',
  angelAndDevilHover = 'angelAndDevilHover',
  angelAndDevil = 'angelAndDevil',
  angelActive = 'angelActive',
  angelHover = 'angelHover',
  angel = 'angel',
  buyFeatureInput = 'buyFeatureInput',
  buyFeaturePopupConfirm = 'buyFeaturePopupConfirm',
  buyFeaturePopup = 'buyFeaturePopup',
  buyFeatureBtnHover = 'buyFeatureBtnHover',
  buyFeatureBtnNotActive = 'buyFeatureBtnNotActive',
  buyFeatureBtnPressed = 'buyFeatureBtnPressed',
  buyFeatureBtn = 'buyFeatureBtn',
  buyFeatureCancelBtnDisable = 'buyFeatureCancelBtnDisable',
  buyFeatureCancelBtnHover = 'buyFeatureCancelBtnHover',
  buyFeatureCancelBtnPressed = 'buyFeatureCancelBtnPressed',
  buyFeatureCancelBtn = 'buyFeatureCancelBtn',
  confirmAngelAndDevilBg = 'confirmAngelAndDevilBg',
  confirmAngelBg = 'confirmAngelBg',
  confirmDevilBg = 'confirmDevilBg',
  devilActive = 'devilActive',
  devilHover = 'devilHover',
  devil = 'devil',
  featureButtonBg = 'featureButtonBg',
  angelDevilMultiplayerFrame = 'angelDevilMultiplayerFrame',
  angelDevilMultiplayerIcon = 'angelDevilMultiplayerIcon',
  angelMultiplayerFrame = 'angelMultiplayerFrame',
  angelMultiplayerIcon = 'angelMultiplayerIcon',
  devilMultiplayerFrame = 'devilMultiplayerFrame',
  devilMultiplayerIcon = 'devilMultiplayerIcon',
  freeSpinBannerAngel = 'freeSpinBannerAngel',
  freeSpinBannerDevil = 'freeSpinBannerDevil',
  buyFeatureMinusBtnDisable = 'buyFeatureMinusBtnDisable',
  buyFeatureMinusBtnHover = 'buyFeatureMinusBtnHover',
  buyFeatureMinusBtnPressed = 'buyFeatureMinusBtnPressed',
  buyFeatureMinusBtn = 'buyFeatureMinusBtn',
  buyFeatureOkBtnDisable = 'buyFeatureOkBtnDisable',
  buyFeatureOkBtnHover = 'buyFeatureOkBtnHover',
  buyFeatureOkBtnPressed = 'buyFeatureOkBtnPressed',
  buyFeatureOkBtn = 'buyFeatureOkBtn',
  buyFeaturePlusBtnDisable = 'buyFeaturePlusBtnDisable',
  buyFeaturePlusBtnHover = 'buyFeaturePlusBtnHover',
  buyFeaturePlusBtnPressed = 'buyFeaturePlusBtnPressed',
  buyFeaturePlusBtn = 'buyFeaturePlusBtn',
  messageBanner = 'messageBanner',
  reelLogo = 'reelLogo',
  frameMobile = 'frameMobile',
  frame = 'frame',
  largeLine = 'largeLine',
  logo = 'logo',
  mediumLine = 'mediumLine',
  reelBackground = 'reelBackground',
  shortLine = 'shortLine',
  a = 'a',
  b = 'b',
  c = 'c',
  cS1 = 'cS1',
  cS2 = 'cS2',
  d = 'd',
  e = 'e',
  f = 'f',
  g = 'g',
  h = 'h',
  i = 'i',
  lock = 'lock',
  sC1 = 'sC1',
  sC2 = 'sC2',
  backdrop = 'backdrop',
  buttonCancel = 'buttonCancel',
  buttonHistory = 'buttonHistory',
  buttonInfo = 'buttonInfo',
  buttonLobby = 'buttonLobby',
  buttonOk = 'buttonOk',
  buttonSetting = 'buttonSetting',
  infoBtnAutoplayStop = 'infoBtnAutoplayStop',
  infoBtnAutoplay = 'infoBtnAutoplay',
  infoBtnBetSettings = 'infoBtnBetSettings',
  infoBtnInfo = 'infoBtnInfo',
  infoBtnMenu = 'infoBtnMenu',
  infoBtnSound = 'infoBtnSound',
  infoBtnSpin = 'infoBtnSpin',
  infoBtnStop = 'infoBtnStop',
  infoBtnTurbo = 'infoBtnTurbo',
  infoFeatureFunction = 'infoFeatureFunction',
  infoPaylines = 'infoPaylines',
  introBg = 'introBg',
  introLight = 'introLight',
  introLogo = 'introLogo',
  introReel1 = 'introReel1',
  introReel2 = 'introReel2',
  introReel3 = 'introReel3',
  multipliersLock = 'multipliersLock',
  popupBg = 'popupBg',
  slotBgAngelAndDevil = 'slotBgAngelAndDevil',
  slotBgAngel = 'slotBgAngel',
  slotBgDevil = 'slotBgDevil',
  slotBg = 'slotBg',
}
