import _ from 'lodash';

import { AudioSprite } from '@phoenix7dev/play-music/dist/d';

export enum ISongs {
  BaseGameBGM_Base = '006_01_BaseGameBGM_Base',
  BaseGameBGM_Melo = '006_02_BaseGameBGM_Melo',
  Angel_BGM_Intro = '006_03_ANGEL_BGM_Intro',
  Angel_BGM_Loop = '006_03_ANGEL_BGM_Loop',
  DEVIL_BGM_Intro = '006_04_DEVIL_BGM_Intro',
  DEVIL_BGM_Loop = '006_04_DEVIL_BGM_Loop',
  AandD_BGM_Intro = '006_05_AandD_BGM_Intro',
  AandD_BGM_Loop = '006_05_AandD_BGM_Loop',
  BigWin_Loop = '006_06_BigWin_Loop',
  BigWin_End = '006_07_BigWin_END',
  HighPayWinning = '006_08_HighPayWinning',
  SymbolErase1 = '006_09_SymbolErase1',
  SymbolErase2 = '006_10_SymbolErase2',
  SymbolErase3 = '006_11_SymbolErase3',
  SymbolErase4 = '006_12_SymbolErase4',
  SymbolErase5 = '006_13_SymbolErase5',
  SymbolErase6 = '006_14_SymbolErase6',
  SymbolErase7 = '006_15_SymbolErase7',
  SymbolErase8 = '006_16_SymbolErase8',
  Scatter1Drop = '006_17_Scatter1Drop',
  Scatter2Drop = '006_18_Scatter2Drop',
  Scatter_Anti = '006_19_Scatter_Anti',
  Angel_Disp = '006_20_Angel_Disp',
  Devil_Disp = '006_21_Devil_Disp',
  Angel_Attack = '006_22_Angel_Attack',
  Devil_Attack = '006_23_Devil_Attack',
  Break = '006_24_Break',
  Mpmove = '006_25_Mpmove',
  TotalWinBanner_ANGEL = '006_26_TotalWinBanner_ANGEL',
  TotalWinBanner_DEVIL = '006_27_TotalWinBanner_DEVIL',
  TotalWinBanner_AandD = '006_28_TotalWinBanner_AandD',
  SFX_BuyFeature = 'SFX_BuyFeature',
  SFX_SM_CountUp_End = 'SFX_SM_CountUp_End',
  SFX_SM_CountUp_Loop = 'SFX_SM_CountUp_Loop',
  SFX_UI_AutoSpin = 'SFX_UI_AutoSpin',
  SFX_UI_BetChange = 'SFX_UI_BetChange',
  SFX_UI_Close = 'SFX_UI_Close',
  SFX_UI_General = 'SFX_UI_General',
  SFX_UI_Hover = 'SFX_UI_Hover',
  SFX_UI_MaxBet = 'SFX_UI_MaxBet',
  SFX_UI_MenuOpen = 'SFX_UI_MenuOpen',
  SFX_UI_SpinStart = 'SFX_UI_SpinStart',
  SFX_UI_SpinStop = 'SFX_UI_SpinStop',
  SFX_WIN_FeatureTrigger = 'SFX_WIN_FeatureTrigger',
  SFX_WIN_BIG = 'SFX_WIN_BIG',
  SFX_WIN_EPIC = 'SFX_WIN_EPIC',
  SFX_WIN_GREAT = 'SFX_WIN_GREAT',
  SFX_WIN_MEGA = 'SFX_WIN_MEGA',
}

export const audioSprite: AudioSprite = {
  [ISongs.BaseGameBGM_Base]: [0, 81509.4375, true],
  [ISongs.BaseGameBGM_Melo]: [83000, 81509.43749999999, true],
  [ISongs.Angel_BGM_Intro]: [166000, 1346.1250000000007],
  [ISongs.Angel_BGM_Loop]: [169000, 49777.81250000001, true],
  [ISongs.DEVIL_BGM_Intro]: [220000, 2053.437500000001],
  [ISongs.DEVIL_BGM_Loop]: [224000, 65084.749999999985, true],
  [ISongs.AandD_BGM_Intro]: [291000, 6786.312500000008],
  [ISongs.AandD_BGM_Loop]: [299000, 104788.75, true],
  [ISongs.BigWin_Loop]: [405000, 49180.312500000015, true],
  [ISongs.BigWin_End]: [456000, 4113.6875000000255],
  [ISongs.HighPayWinning]: [462000, 1905.5000000000177],
  [ISongs.SymbolErase1]: [465000, 1363.8750000000073],
  [ISongs.SymbolErase2]: [468000, 1235.437499999989],
  [ISongs.SymbolErase3]: [471000, 1064.687499999991],
  [ISongs.SymbolErase4]: [474000, 1221.437499999979],
  [ISongs.SymbolErase5]: [477000, 1271.0625000000277],
  [ISongs.SymbolErase6]: [480000, 1303.9999999999736],
  [ISongs.SymbolErase7]: [483000, 1318.3750000000032],
  [ISongs.SymbolErase8]: [486000, 1277.8124999999818],
  [ISongs.Scatter1Drop]: [489000, 1972.750000000019],
  [ISongs.Scatter2Drop]: [492000, 372.68749999998363],
  [ISongs.Scatter_Anti]: [494000, 162.43750000001],
  [ISongs.Angel_Disp]: [496000, 3736.9375000000105],
  [ISongs.Devil_Disp]: [501000, 2274.0625000000136],
  [ISongs.Angel_Attack]: [505000, 1121.3124999999877],
  [ISongs.Devil_Attack]: [508000, 1390.4999999999745],
  [ISongs.Break]: [511000, 2001.062499999989],
  [ISongs.Mpmove]: [515000, 1152.8124999999818],
  [ISongs.TotalWinBanner_ANGEL]: [518000, 10275.62499999999],
  [ISongs.TotalWinBanner_DEVIL]: [530000, 6631.3125000000355],
  [ISongs.TotalWinBanner_AandD]: [538000, 11471.000000000004],
  [ISongs.SFX_BuyFeature]: [551000, 666.6874999999663],
  [ISongs.SFX_SM_CountUp_End]: [553000, 933.4374999999682],
  [ISongs.SFX_SM_CountUp_Loop]: [555000, 399.75000000004],
  [ISongs.SFX_UI_AutoSpin]: [557000, 320.43750000002547],
  [ISongs.SFX_UI_BetChange]: [559000, 32.06250000005184],
  [ISongs.SFX_UI_Close]: [561000, 200.68749999995816],
  [ISongs.SFX_UI_General]: [563000, 88.3125000000291],
  [ISongs.SFX_UI_Hover]: [565000, 151.74999999999272],
  [ISongs.SFX_UI_MaxBet]: [567000, 122.93750000003456],
  [ISongs.SFX_UI_MenuOpen]: [569000, 277.12499999995543],
  [ISongs.SFX_UI_SpinStart]: [571000, 249.56250000002456],
  [ISongs.SFX_UI_SpinStop]: [573000, 150.99999999995362],
  [ISongs.SFX_WIN_FeatureTrigger]: [575000, 879.8124999999573],
  [ISongs.SFX_WIN_BIG]: [577000, 1622.312500000021],
  [ISongs.SFX_WIN_EPIC]: [580000, 1862.2500000000173],
  [ISongs.SFX_WIN_GREAT]: [583000, 2118.3750000000146],
  [ISongs.SFX_WIN_MEGA]: [587000, 1696.187499999951],
};

export const audioSpriteVolume: Record<ISongs, number> = {
  [ISongs.BaseGameBGM_Base]: 0.2,
  [ISongs.BaseGameBGM_Melo]: 0.1,
  [ISongs.Angel_BGM_Intro]: 0.2,
  [ISongs.Angel_BGM_Loop]: 0.2,
  [ISongs.DEVIL_BGM_Intro]: 0.2,
  [ISongs.DEVIL_BGM_Loop]: 0.2,
  [ISongs.AandD_BGM_Intro]: 0.2,
  [ISongs.AandD_BGM_Loop]: 0.2,
  [ISongs.BigWin_Loop]: 0.3,
  [ISongs.BigWin_End]: 0.3,
  [ISongs.HighPayWinning]: 0.3,
  [ISongs.SymbolErase1]: 0.3,
  [ISongs.SymbolErase2]: 0.3,
  [ISongs.SymbolErase3]: 0.3,
  [ISongs.SymbolErase4]: 0.3,
  [ISongs.SymbolErase5]: 0.4,
  [ISongs.SymbolErase6]: 0.4,
  [ISongs.SymbolErase7]: 0.4,
  [ISongs.SymbolErase8]: 0.4,
  [ISongs.Scatter1Drop]: 0.3,
  [ISongs.Scatter2Drop]: 0.3,
  [ISongs.Scatter_Anti]: 0.3,
  [ISongs.Angel_Disp]: 0.3,
  [ISongs.Devil_Disp]: 0.3,
  [ISongs.Angel_Attack]: 0.4,
  [ISongs.Devil_Attack]: 0.4,
  [ISongs.Break]: 0.4,
  [ISongs.Mpmove]: 0.5,
  [ISongs.TotalWinBanner_ANGEL]: 0.4,
  [ISongs.TotalWinBanner_DEVIL]: 0.4,
  [ISongs.TotalWinBanner_AandD]: 0.4,
  [ISongs.SFX_BuyFeature]: 0.4,
  [ISongs.SFX_SM_CountUp_End]: 0.3,
  [ISongs.SFX_SM_CountUp_Loop]: 0.3,
  [ISongs.SFX_UI_AutoSpin]: 0.4,
  [ISongs.SFX_UI_BetChange]: 0.4,
  [ISongs.SFX_UI_Close]: 0.4,
  [ISongs.SFX_UI_General]: 0.4,
  [ISongs.SFX_UI_Hover]: 0.4,
  [ISongs.SFX_UI_MaxBet]: 0.4,
  [ISongs.SFX_UI_MenuOpen]: 0.4,
  [ISongs.SFX_UI_SpinStart]: 0.4,
  [ISongs.SFX_UI_SpinStop]: 0.4,
  [ISongs.SFX_WIN_FeatureTrigger]: 0.3,
  [ISongs.SFX_WIN_BIG]: 0.3,
  [ISongs.SFX_WIN_EPIC]: 0.3,
  [ISongs.SFX_WIN_GREAT]: 0.3,
  [ISongs.SFX_WIN_MEGA]: 0.3,
};

export const audioSpriteGaps: { [key in ISongs]?: number } = {
  // [ISongs.Music_BigWin_End]: 2000,
};

export const mappedAudioSprites = _.reduce(
  audioSprite,
  (acc, value, key) => {
    _.set(acc, key, {
      start: value[0],
      duration: value[1] - (audioSpriteGaps[key as ISongs] || 0),
      loop: !!value[2],
    });
    return acc;
  },
  {},
) as {
  [key in ISongs]: { start: number; duration: number; loop: boolean };
};
