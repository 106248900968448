import i18n from 'i18next';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes } from '../../global.d';
import { setIsBuyFeaturePurchased } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { FeatureState, getBonusIdByFeature, updateTextScale } from '../../utils';
import { SpriteButton, SpriteButtonState } from '../components/spriteButton';
import {
  FEATURE_CONFIRM_COST_Y,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_HEIGHT,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X,
  FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y,
  FEATURE_POPUP_CONFIRM_POSITION_X,
  FEATURE_POPUP_CONFIRM_POSITION_Y,
  FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y,
  FEATURE_POPUP_CONFIRM_WIDTH,
  FEATURE_TITLE_Y,
  FEATURE_TOTAL_COST_Y,
  MOBILE_FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X,
  MOBILE_FEATURE_POPUP_CONFIRM_POSITION_X,
  MOBILE_FEATURE_POPUP_CONFIRM_POSITION_Y,
  MOBILE_FEATURE_POPUP_CONFIRM_WIDTH,
  MOBILE_TOTAL_COST_VALUE,
  eventManager,
} from '../config';

import { buyFeatureTitleStyleB, buyFeatureTitleStyleC } from './textStyles';

class BuyFeaturePopupConfirm extends PIXI.Container {
  private angelBgTexture = PIXI.Texture.from(ResourceTypes.confirmAngelBg);

  private devilBgTexture = PIXI.Texture.from(ResourceTypes.confirmDevilBg);

  private angelAndDevilBgTexture = PIXI.Texture.from(ResourceTypes.confirmAngelAndDevilBg);

  private popupBg: PIXI.Sprite = new PIXI.Sprite(this.angelBgTexture);

  private coinAmount: number;

  private featureState: FeatureState = FeatureState.ANGEL;

  private titleText: PIXI.Text;

  private titleFeature: PIXI.Text;

  private totalCostText: PIXI.Text;

  private totalCostValue: PIXI.Text;

  private okBtn: PIXI.Sprite;

  private cancelBtn: PIXI.Sprite;

  constructor() {
    super();
    this.x = 0;
    this.y = 75;
    this.visible = false;
    this.coinAmount = 1;
    this.initPopupBg();
    this.titleText = this.initTitle();
    this.titleFeature = this.initTitleFeature();
    this.totalCostText = this.initTotalCostText();
    this.totalCostValue = this.initTotalCostValue();
    this.cancelBtn = this.initCancelBtn();
    this.okBtn = this.initOkBtn();
    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => this.handleClose());
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => this.handleClose(true));
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP, this.handleOpen.bind(this));
    eventManager.on(EventTypes.FORCE_CLOSE_BUYFEATURE, () => {
      this.handleClose();
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.titleText);
    this.addChild(this.titleFeature);
    this.addChild(this.totalCostText);
    this.addChild(this.totalCostValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
  }

  private initPopupBg(): void {
    this.popupBg.height = FEATURE_POPUP_CONFIRM_HEIGHT;
    this.popupBg.width = isMobile ? MOBILE_FEATURE_POPUP_CONFIRM_WIDTH : FEATURE_POPUP_CONFIRM_WIDTH;
    this.popupBg.x = isMobile ? MOBILE_FEATURE_POPUP_CONFIRM_POSITION_X : FEATURE_POPUP_CONFIRM_POSITION_X;
    this.popupBg.y = isMobile ? MOBILE_FEATURE_POPUP_CONFIRM_POSITION_Y : FEATURE_POPUP_CONFIRM_POSITION_Y;
  }

  private initTitle(): PIXI.Text {
    const title = new PIXI.Text(i18n.t('buyFeatureConfirmTitle'), buyFeatureTitleStyleB as Partial<PIXI.ITextStyle>);
    title.y = FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y;
    title.x = isMobile ? this.popupBg.width / 2 : this.popupBg.width / 2 - 40;
    title.anchor.set(0.5, 0.5);
    return title;
  }

  private initTitleFeature(): PIXI.Text {
    const titleFeature = new PIXI.Text(
      i18n.t('buyFeatureConfirmAngel'),
      buyFeatureTitleStyleC as Partial<PIXI.ITextStyle>,
    );
    titleFeature.anchor.set(0.5, 0.5);
    titleFeature.y = FEATURE_TITLE_Y;
    titleFeature.x = isMobile ? this.popupBg.width / 2 : this.popupBg.width / 2 - 40;
    return titleFeature;
  }

  private initTotalCostText(): PIXI.Text {
    const titleCost = new PIXI.Text(i18n.t('buyFeatureTotalCost'), buyFeatureTitleStyleB as Partial<PIXI.ITextStyle>);
    titleCost.y = FEATURE_CONFIRM_COST_Y;
    titleCost.x = isMobile ? this.popupBg.width / 2 : this.popupBg.width / 2 - 40;

    titleCost.anchor.set(0.5, 0.5);

    return titleCost;
  }

  private initTotalCostValue(): PIXI.Text {
    const totalCostValue = new PIXI.Text('0', buyFeatureTitleStyleC as Partial<PIXI.ITextStyle>);
    totalCostValue.y = FEATURE_TOTAL_COST_Y;
    totalCostValue.x = isMobile ? MOBILE_TOTAL_COST_VALUE : this.popupBg.width / 2 - 40;
    totalCostValue.anchor.set(0.5, 0.5);
    return totalCostValue;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      },
      onTouchStart: () => {
        this.handleClose();
        eventManager.emit(EventTypes.OPEN_BUY_FEATURE_POPUP_BG);
        AudioHowl.play({ type: ISongs.SFX_UI_Close });
      },
    });
    cancelBtn.anchor.set(0.5, 0.5);
    cancelBtn.y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = isMobile
      ? MOBILE_FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X
      : FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X;

    return cancelBtn;
  }

  private initOkBtn(): PIXI.Sprite {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: () => {
        AudioHowl.play({ type: ISongs.SFX_BuyFeature });
        this.startBuyFeature();
      },
      onTouchStart: () => {
        AudioHowl.play({ type: ISongs.SFX_BuyFeature });
        this.startBuyFeature();
      },
    });

    okBtn.anchor.set(0.5, 0.5);
    okBtn.y = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X;
    return okBtn;
  }

  private startBuyFeature(): void {
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.HANDLE_BUY_BONUS, getBonusIdByFeature(this.featureState));
    setIsBuyFeaturePurchased(true);
  }

  public handleOpen(totalCost: string, coinAmount: number, featureState: FeatureState): void {
    eventManager.emit(EventTypes.HIDE_FRAME);
    this.featureState = featureState;
    if (featureState === FeatureState.ANGEL) {
      this.titleFeature.text = i18n.t('buyFeatureConfirmAngel');
      this.popupBg.texture = this.angelBgTexture;
      updateTextScale(this.titleFeature, isMobile ? 750 : 750, isMobile ? 250 : 250);
    }
    if (featureState === FeatureState.DEVIL) {
      this.titleFeature.text = i18n.t('buyFeatureConfirmDevil');
      this.popupBg.texture = this.devilBgTexture;
      updateTextScale(this.titleFeature, isMobile ? 750 : 750, isMobile ? 250 : 250);
    }
    if (featureState === FeatureState.ANGEL_AND_DEVIL) {
      this.titleFeature.text = i18n.t('buyFeatureConfirmAngelAndDevil');
      this.popupBg.texture = this.angelAndDevilBgTexture;
      updateTextScale(this.titleFeature, isMobile ? 750 : 750, isMobile ? 250 : 250);
    }
    this.visible = true;
    this.coinAmount = coinAmount;
    this.totalCostValue.text = totalCost;
    this.okBtn.interactive = true;
    this.cancelBtn.interactive = true;
  }

  public handleClose(closeBuyFeature?: boolean): void {
    const closeBuyFeaturePopup = closeBuyFeature || setIsBuyFeaturePurchased();
    this.okBtn.interactive = false;
    this.cancelBtn.interactive = false;
    eventManager.emit(EventTypes.SHOW_FRAME);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP, closeBuyFeaturePopup);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP_BG);
    this.visible = false;
  }
}

export default BuyFeaturePopupConfirm;
