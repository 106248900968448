// eslint-disable-next-line max-classes-per-file
import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import {
  ISongs,
  MAPPED_SYMBOLS_LAND_ANIMATIONS,
  MAPPED_SYMBOLS_WIN_ANIMATIONS,
  SlotId,
  mappedAudioSprites,
} from '../../config';
import { Cascade, EventTypes } from '../../global.d';
import { setCurrency, setGameMode, setIsTurboSpin } from '../../gql/cache';
import {
  delayedAction,
  formatNumber,
  getAnticipationPositions,
  getClosestPosition,
  isAngelSlotId,
  isHighPaySymbols,
  nextTick,
  normalizeCoins,
  showCurrency,
} from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import { WinCountUpMessageStyle } from '../buyFeature/textStyles';
import ViewContainer from '../components/container';
import {
  ANTICIPATION_ENABLE,
  COUNT_UP_WIN_AMOUNT_TEXT_DURATION,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  eventManager,
} from '../config';
import { Icon } from '../d';

class WinSlotsContainer extends ViewContainer {
  public animation: Animation | null = null;

  public loopAnimation: Animation | null = null;

  constructor() {
    super();
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
    eventManager.addListener(EventTypes.START_WIN_ANIMATION, this.showWin.bind(this));
    eventManager.addListener(EventTypes.START_SCATTER_WIN_ANIMATION, this.startScatterWinAnimation.bind(this));
  }

  private startScatterWinAnimation(spinResult: Icon[], positions: number[]): void {
    const animationGroup = new AnimationGroup();
    positions.forEach((position) => {
      animationGroup.addAnimation(
        this.createSlotSpineAnimation(
          position,
          spinResult[position].id,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[spinResult[position].id].src!,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[spinResult[position].id].animation!,
        ),
      );
    });
    animationGroup.addOnStart(() => eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, positions, false));
    animationGroup.addOnComplete(() => eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, positions, true));
    animationGroup.start();
  }

  private createScatterAnticipationAnimation(spinResult: Icon[], positions: number[]): Animation {
    const animationGroup = new AnimationGroup();
    positions.forEach((position) => {
      animationGroup.addAnimation(
        this.createSlotSpineAnimation(
          position,
          spinResult[position].id,
          MAPPED_SYMBOLS_LAND_ANIMATIONS[spinResult[position].id].src!,
          MAPPED_SYMBOLS_LAND_ANIMATIONS[spinResult[position].id].animation!,
          true,
        ),
      );
    });
    return animationGroup;
  }

  private createWinAmountTextAnimation(winPositions: number[], winAmount: number): Animation {
    const position = getClosestPosition(winPositions);
    const text = new PIXI.Text(
      formatNumber(setCurrency(), normalizeCoins(winAmount), showCurrency(setCurrency())),

      WinCountUpMessageStyle as Partial<PIXI.ITextStyle>,
    );
    text.anchor.set(0.5, 0.5);
    text.x = ((position % 6) + 0.5) * REEL_WIDTH;
    text.y = (Math.floor(position / 6) + 0.5) * SLOT_HEIGHT;
    const animation = Tween.createDelayAnimation(COUNT_UP_WIN_AMOUNT_TEXT_DURATION);
    animation.addOnStart(() => {
      this.addChild(text);
    });
    animation.addOnComplete(() => {
      this.removeChild(text);
    });

    return animation;
  }

  private createSlotSpineAnimation(
    id: number,
    slotId: SlotId,
    srcName: string,
    animationName: string,
    disableExplosion?: boolean,
  ): Animation {
    const timeScale = setIsTurboSpin() ? 1.5 : 1;
    const dummy = Tween.createDelayAnimation((disableExplosion ? 800 : 1200) / timeScale);
    const animation = new Spine(PIXI.Loader.shared.resources[srcName].spineData!);
    const explosion = new Spine(PIXI.Loader.shared.resources.explosion.spineData!);
    explosion.state.timeScale = timeScale;
    animation.state.timeScale = timeScale;

    dummy.addOnStart(() => {
      if (disableExplosion) {
        eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [id], false);
      }
      this.addChild(animation);
      animation.state.addListener({
        complete: (entry) => {
          nextTick(() => {
            animation.destroy();
            if (disableExplosion) {
              eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, [id], true);
            }
          });
        },
      });
      animation.state.setAnimation(0, animationName, false);
      animation.x = REEL_WIDTH / 2 + REEL_WIDTH * (id % 6);
      animation.y = SLOT_HEIGHT * Math.floor(id / 6) + SLOT_HEIGHT / 2;
      const explosionType = isAngelSlotId(slotId) ? 'angel_explosion' : 'devil_explosion';
      if (!disableExplosion) {
        explosion.x = REEL_WIDTH / 2 + REEL_WIDTH * (id % 6);
        explosion.y = SLOT_HEIGHT * Math.floor(id / 6) + SLOT_HEIGHT / 2;
        this.addChild(explosion);
      }
      // todo replace it with spine event
      delayedAction(800 / timeScale, () => {
        if (!disableExplosion) {
          explosion.state.addListener({
            complete: (entry) => {
              nextTick(() => explosion.destroy());
            },
          });
          explosion.state.setAnimation(0, explosionType, false);
        }
      });
    });

    return dummy;
  }

  private showWin(spinResult: Icon[], cascade: Cascade, id: number): void {
    const currentSpinResult = [...spinResult];
    this.animation = this.createCascadeAnimation(currentSpinResult, cascade, id);
    this.animation.addOnComplete(() => {
      eventManager.emit(EventTypes.NEXT_CASCADE, id + 1);
    });
    this.animation.start();
  }

  private createCascadeAnimation(spinResult: Icon[], cascades: Cascade, id: number): Animation {
    const chain = new AnimationChain();
    const animationGroup = new AnimationGroup();
    const paylines = cascades.winPositions.reduce((res, current) => {
      return [...res, ...current];
    }, []);
    if (isHighPaySymbols(spinResult, paylines)) {
      animationGroup.addOnStart(() => {
        AudioHowl.play({ type: ISongs.HighPayWinning });
      });
    }
    paylines.forEach((winId) => {
      animationGroup.addAnimation(
        this.createSlotSpineAnimation(
          winId,
          spinResult[winId].id,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[spinResult[winId].id].src!,
          MAPPED_SYMBOLS_WIN_ANIMATIONS[spinResult[winId].id].animation!,
        ),
      );
    });
    animationGroup.addOnStart(() => {
      eventManager.emit(EventTypes.SET_SLOTS_VISIBILITY, paylines, false);
    });
    animationGroup.addOnComplete(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const songId = ISongs[`SymbolErase${id < 8 ? id + 1 : 8}`];
      AudioHowl.play({ type: songId });
    });
    const winAmountGroup = new AnimationGroup();
    cascades.winPositions.forEach((winPositions, index) => {
      winAmountGroup.addAnimation(this.createWinAmountTextAnimation(winPositions, cascades.winAmounts[index]));
    });
    chain.appendAnimation(animationGroup);
    chain.appendAnimation(winAmountGroup);
    const anticipationPositions = getAnticipationPositions(spinResult, setGameMode());
    if (ANTICIPATION_ENABLE && anticipationPositions.length > 0) {
      chain.appendAnimation(this.createAnticipationAnimation(spinResult, anticipationPositions));
    }
    return chain;
  }

  private createAnticipationAnimation(spinResult: Icon[], anticipationPositions: number[]): Animation {
    const anticipationAnimation = this.createScatterAnticipationAnimation(spinResult, anticipationPositions);
    anticipationAnimation.addOnStart(() => AudioHowl.play({ type: ISongs.Scatter_Anti, stopPrev: true }));
    return anticipationAnimation;
  }
}

export default WinSlotsContainer;
