import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { config } from '../../config';
import { IUserBalance } from '../../global.d';
import { setCoinValue, setSlotConfig } from '../../gql/cache';
import { getBetAmountGql, getUserGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { countCoins, formatNumber, showCurrency } from '../../utils';
import Resources from '../../utils/resources';

import { IPaytableData } from './d';
import styles from './info.module.scss';

const calcMultiplier = (multiplier: number, betAmount: number, slug: ResourceTypes): number => {
  if (slug === ResourceTypes.cS1 || slug === ResourceTypes.cS2)
    return countCoins({ totalAmount: betAmount, coinValue: setCoinValue() }) * multiplier;
  return (
    countCoins({
      totalAmount: betAmount / setSlotConfig().lineSets[0].coinAmountMultiplier,
      coinValue: setCoinValue(),
    }) * multiplier
  );
};

const hasTranslate = (slug: ResourceTypes): boolean => {
  return slug === ResourceTypes.cS2 || slug === ResourceTypes.cS1;
};

const PaytableComponent: React.FC = () => {
  const { t } = useTranslation();
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataBet } = useQuery<{
    betAmount: number;
  }>(getBetAmountGql);

  const {
    user: { balance },
  } = userData!;

  const { betAmount } = dataBet!;

  const data: IPaytableData[] = config.payTableData;

  const { currency } = balance;

  return (
    <div className={styles['paytable-list']}>
      {data.map(({ combos, slug }) => (
        <div key={slug} className={styles['paytable-list__item']}>
          {/* dynamic used keys */}
          {/* t('infoPayTable_cS1') */
          /* t('infoPayTable_cS2') */}
          <div className={styles.img}>
            <img draggable="false" alt={slug} src={Resources.getSource(slug)} />
            {hasTranslate(slug) && <div className={styles['paytable-list__title']}>{t(`infoPayTable_${slug}`)}</div>}
          </div>
          <table className={styles.content}>
            {combos.map((i) => (
              <tr key={i.pattern}>
                <td className={styles.multiplier}>{i.pattern}</td>
                <td>{formatNumber(currency, calcMultiplier(i.multiplier, betAmount, slug), showCurrency(currency))}</td>
              </tr>
            ))}
          </table>
        </div>
      ))}
    </div>
  );
};

export default PaytableComponent;
