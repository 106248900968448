import * as PIXI from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

export const textStyle = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  // dropShadowAngle: 0.785,
  dropShadowBlur: 3,
  dropShadowColor: '#4f3480',
  dropShadowDistance: 4,
  fill: ['#f9fb4c', '#edb757', '#bf8220', '#cba152'],
  // fillGradientStops: [0, 0.31, 0.68, 1],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 40,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#4f3480',
  strokeThickness: 4,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const spinsStyleAngel = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#7ffbfb', '#c9fdfd'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 50,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#114171',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const spinsStyleDevil = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#f83636', '#fb8080'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 50,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#530101',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};

export const spinsStyleAngelandDevil = {
  align: 'center',
  breakWords: true,
  dropShadow: true,
  dropShadowAlpha: 0.75,
  dropShadowAngle: 0.785,
  dropShadowBlur: 10,
  dropShadowColor: 0x000000,
  dropShadowDistance: 4,
  fill: ['#d866fe', '#ebb2fe'],
  fillGradientType: PIXI.TEXT_GRADIENT.LINEAR_VERTICAL,
  fontFamily: font,
  fontSize: 50,
  fontStyle: 'normal',
  fontVariant: 'normal',
  leading: 0,
  letterSpacing: 0,
  lineJoin: 'round',
  miterLimit: 10,
  padding: 4,
  stroke: '#450c79',
  strokeThickness: 6,
  trim: false,
  textBaseline: 'alphabetic',
  whiteSpace: 'pre',
  wordWrap: false,
  wordWrapWidth: 1330,
};
