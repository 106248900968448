import { isMobile } from 'mobile-device-detect';

import { EventTypes } from '../../global.d';
import { setSlotConfig } from '../../gql/cache';
import { calcBottomContainerHeight } from '../../utils';
import ViewContainer from '../components/container';
import { SAFE_AREA_DESKTOP_BOTTOM_PADDING, SAFE_AREA_MOBILE_BOTTOM_PADDING, eventManager } from '../config';

class SafeArea extends ViewContainer {
  private isEnabledFreeSpins: boolean;

  constructor() {
    super();
    this.isEnabledFreeSpins = setSlotConfig().isBuyFeatureEnabled;
    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
  }

  private calculateSize(width: number, height: number): [number, number, number, boolean] {
    let newWidth = 0;
    let newHeight = 0;

    const bottomContainerHeight = calcBottomContainerHeight(width, height);
    const bottomPadding = isMobile ? SAFE_AREA_MOBILE_BOTTOM_PADDING : SAFE_AREA_DESKTOP_BOTTOM_PADDING;
    const isRectangleRatio = +(width / height).toFixed(2) >= 1;
    const bottomSpace = isRectangleRatio
      ? bottomContainerHeight + bottomPadding
      : bottomContainerHeight + bottomPadding + 50;
    if (isRectangleRatio) {
      if (isMobile && this.isEnabledFreeSpins) {
        newWidth = Math.min(height - bottomSpace, width * 0.6);
        newHeight = Math.min(height - bottomSpace, width * 0.6);
      } else {
        newWidth = (height - bottomSpace) * 0.925;
        newHeight = (height - bottomSpace) * 0.925;
      }
    } else if (isMobile && this.isEnabledFreeSpins) {
      newWidth = width - 30;
      newHeight = Math.min(height, newWidth - bottomSpace);
    } else {
      newWidth = width - 18;
      newHeight = width * 1.25 - bottomSpace;
    }

    return [newWidth, newHeight, bottomSpace, isRectangleRatio];
  }

  private resize(width: number, height: number): void {
    const [newWidth, newHeight, bottomSpace, isLandscape] = this.calculateSize(width, height);
    const x = (width - newWidth) / 2;
    const margin = Math.max(320 * (width / height), 0);
    const y = Math.max((height - newHeight - bottomSpace - (isLandscape ? 0 : margin)) / 2, 0);
    this.position.set(x, y);

    eventManager.emit(
      EventTypes.RESIZE_GAME_CONTAINER,
      newWidth,
      newHeight,
      Math.max((width - newWidth) / 2, 0),
      Math.max((height - newHeight - bottomSpace) / 2, 0),
    );
  }
}
export default SafeArea;
