import { EventTypes } from '../global.d';
import {
  setAutoSpinsLeft,
  setIsAutoSpins,
  setIsBuyFeaturePurchased,
  setIsErrorMessage,
  setIsSlotBusy,
  setSlotConfig,
  setUserLastBetResult,
} from '../gql/cache';
import SlotMachine from '../slotMachine';
import { eventManager } from '../slotMachine/config';

import { getCascadeColumns } from './helper';

export const fallBackReelPosition = (): void => {
  if (setIsBuyFeaturePurchased()) {
    eventManager.emit(EventTypes.FORCE_CLOSE_BUYFEATURE);
  } else {
    setIsErrorMessage(true);
    const { reels, settings } = setSlotConfig();
    const [firstReel] = reels;
    let reelSet;
    if (setUserLastBetResult().id) {
      if (setUserLastBetResult().reelSet) {
        reelSet = setUserLastBetResult().reelSet;
      } else {
        reelSet = reels.find((reelSet) => reelSet.id === setUserLastBetResult().reelSetId)!;
      }
    } else {
      reelSet = firstReel;
    }
    if (reelSet) {
      const startPosition = setUserLastBetResult().id
        ? setUserLastBetResult().result.reelPositions
        : settings.startPosition;
      eventManager.emit(
        EventTypes.SETUP_REEL_POSITIONS,
        getCascadeColumns({
          reelPositions: startPosition,
          layout: reelSet!.layout,
          cascades: [],
        }),
        SlotMachine.getInstance().isStopped,
      );

      eventManager.emit(EventTypes.END_WAITING_ANIMATION);
      if (setIsAutoSpins()) {
        setAutoSpinsLeft(0);
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      }
    }
    eventManager.emit(EventTypes.FORCE_STOP_AUTOPLAY);
  }
};
