import AudioHowl from '@phoenix7dev/play-music';
import { Play } from '@phoenix7dev/play-music/dist/d';
import * as PIXI from 'pixi.js';
import { DropShadowFilter, DropShadowFilterOptions } from 'pixi-filters';

import { ISongs, SlotId } from '../config';
import {
  bonusesId,
  EventTypes,
  GameMode,
  ISettledBet,
  ReelSet,
  reelSets,
} from '../global.d';
import {
  setBetAmount,
  setBrokenGame,
  setIsSuspended,
  setSlotConfig,
} from '../gql/cache';
import { IConfig } from '../gql/d';
import SentryRaven from '../sentryRaven';
import Tween from '../slotMachine/animations/tween';
import {
  ANTICIPATION_SYMBOLS_AMOUNT,
  ANTICIPATION_SYMBOLS_ID,
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  eventManager,
  GREAT_WIN_AMOUNT_LIMIT,
  HIGH_PAYING_SYMBOLS,
  MEGA_WIN_AMOUNT_LIMIT,
  SlotMachineState,
  WinStages,
} from '../slotMachine/config';
import { Icon } from '../slotMachine/d';
import { normalizeCoins } from './utils';

declare namespace Helper {
  export type RestArguments = unknown[];
  export type Callback<T> = (...args: RestArguments) => T;
  export interface WrapArguments<T> {
    (fn: Callback<T>, ...partOne: RestArguments): Callback<T>;
  }
}

export enum FeatureState {
  ANGEL,
  DEVIL,
  ANGEL_AND_DEVIL,
}

export const getWsUtl = (url: string): string => {
  const { protocol, host } = window.location;
  return `${protocol.replace('http', 'ws')}//${host}${url}`;
};

export const parseQuery = <T>(): T => {
  const { search } = window.location;
  const str = search
    .slice(1)
    .split('&')
    .map((i) => i.split('='));

  const param = str.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return param as T;
};

export const goToLobby = (): void => {
  const { home } = parseQuery<{ home: string }>();
  if (home) {
    window.parent.postMessage(`goTo:${home}`, '*');
  } else {
    window.parent.postMessage('goTo:', '*');
  }
};

export const wrap = (
  fn: CallableFunction,
  ...partOne: Helper.RestArguments
) => (...partTwo: Helper.RestArguments): unknown => {
  const args: Helper.RestArguments = [...partOne, ...partTwo];
  if (args.length) {
    return fn(...args);
  }
  return fn();
};

export const isMobileDevice = (): boolean => {
  const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|WPDesktop/;
  return (
    regex.test(window.navigator.userAgent) ||
    (window.navigator.platform === 'MacIntel' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      typeof window.navigator.standalone !== 'undefined')
  );
};

export const getUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    if (config[name] === undefined) {
      Object.assign(config, { [name]: value });
      localStorage.setItem('config', JSON.stringify(config));
    }
    return config[name] as boolean;
  }
  localStorage.setItem('config', JSON.stringify({ [name]: value }));
  return value;
};

export const setUserConfig = (name: string, value: boolean): boolean => {
  const item = localStorage.getItem('config');
  if (item) {
    const config: IConfig = JSON.parse(item);
    Object.assign(config, { [name]: value });
    localStorage.setItem('config', JSON.stringify(config));
  }
  return value;
};

export const normalize = (coord: number, layout: string[]) => {
  return coord < 0
    ? layout.length - (Math.abs(coord) % layout.length)
    : coord % layout.length;
};

export const getFreeSpinsTitleByGameMode = (gameMode: GameMode): string => {
  if (gameMode === GameMode.ANGEL) {
    return 'angelFeatureTitle';
  }
  if (gameMode === GameMode.DEVIL) {
    return 'devilFeatureTitle';
  }
  return 'angelAndDevilFeatureTitle';
};

export const getBonusIdByFeature = (featureState: FeatureState): string => {
  if (featureState === FeatureState.ANGEL)
    return bonusesId[GameMode.BUY_FEATURE_ANGEL];
  if (featureState === FeatureState.DEVIL)
    return bonusesId[GameMode.BUY_FEATURE_DEVIL];
  return bonusesId[GameMode.BUY_FEATURE_ANGEL_AND_DEVIL];
};

export const isFreeSpinMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.ANGEL ||
    mode === GameMode.DEVIL ||
    mode === GameMode.ANGEL_AND_DEVIL
  );
};

export const isScatter = (slotId: SlotId): boolean => {
  return [
    SlotId.SC1,
    SlotId.SC2,
    SlotId.SC3,
    SlotId.SC4,
    SlotId.SC5,
    SlotId.SC6,
    SlotId.SC7,
    SlotId.SC8,
  ].includes(slotId);
};

export const isAngelSlotId = (slotId: SlotId): boolean => {
  return [
    SlotId.SC1,
    SlotId.SC3,
    SlotId.SC5,
    SlotId.SC7,
    SlotId.A,
    SlotId.B,
    SlotId.D,
    SlotId.F,
    SlotId.H,
  ].includes(slotId);
};
export const isBuyFeatureMode = (mode: GameMode): boolean => {
  return (
    mode === GameMode.BUY_FEATURE_ANGEL ||
    mode === GameMode.BUY_FEATURE_DEVIL ||
    mode === GameMode.BUY_FEATURE_ANGEL_AND_DEVIL
  );
};

export const getClosestPosition = (winPositions: number[]): number => {
  const order = [
    20,
    14,
    26,
    19,
    21,
    13,
    15,
    25,
    27,
    8,
    7,
    9,
    16,
    22,
    28,
    32,
    31,
    33,
    18,
    12,
    24,
    6,
    30,
    10,
    34,
    2,
    1,
    3,
    23,
    17,
    29,
    11,
    4,
    0,
    5,
    35,
  ];
  let closestIndex = order.indexOf(winPositions[0]);
  for (let i = 1; i < winPositions.length; i++) {
    closestIndex = Math.min(closestIndex, order.indexOf(winPositions[i]));
  }
  return order[closestIndex];
};

export const getWinStage = (nextResult: ISettledBet): WinStages => {
  const betAmount = normalizeCoins(setBetAmount());
  const winAmount = normalizeCoins(nextResult.bet.result.winCoinAmount);
  const multiplier = winAmount / betAmount;

  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (
    multiplier >= DOUBLE_WIN_AMOUNT_LIMIT &&
    multiplier < BASE_WIN_AMOUNT_LIMIT
  ) {
    return WinStages.BaseWin;
  }
  if (
    multiplier >= BASE_WIN_AMOUNT_LIMIT &&
    multiplier < BIG_WIN_AMOUNT_LIMIT
  ) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT)
    return WinStages.MegaWin;
  if (
    multiplier >= MEGA_WIN_AMOUNT_LIMIT &&
    multiplier < GREAT_WIN_AMOUNT_LIMIT
  )
    return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const isBuyFeatureReelSet = (reelSetId: string): boolean => {
  return (
    reelSets[GameMode.BUY_FEATURE_ANGEL] === reelSetId ||
    reelSets[GameMode.BUY_FEATURE_DEVIL] === reelSetId ||
    reelSets[GameMode.BUY_FEATURE_ANGEL_AND_DEVIL] === reelSetId
  );
};
export const isBuyFeatureBonus = (bonusId: string): boolean => {
  return (
    bonusesId[GameMode.BUY_FEATURE_ANGEL] === bonusId ||
    bonusesId[GameMode.BUY_FEATURE_DEVIL] === bonusId ||
    bonusesId[GameMode.BUY_FEATURE_ANGEL_AND_DEVIL] === bonusId
  );
};
export const isFreeSpinsBonus = (bonusId: string): boolean => {
  return (
    bonusesId[GameMode.ANGEL] === bonusId ||
    bonusesId[GameMode.DEVIL] === bonusId ||
    bonusesId[GameMode.ANGEL_AND_DEVIL] === bonusId
  );
};
export const isRegularMode = (mode: GameMode): boolean => {
  return mode === GameMode.REGULAR || mode === GameMode.FREE_ROUND_BONUS;
};

export const isFreeRoundBonusMode = (mode: GameMode): boolean => {
  return mode === GameMode.FREE_ROUND_BONUS;
};

export const nextTick = (callback: () => void): void => {
  setImmediate(callback);
};
export const countCoins = (bet: {
  totalAmount?: number;
  coinAmount?: number;
  coinValue?: number;
  lines?: number;
}): number => {
  if (bet.totalAmount) {
    return (bet.totalAmount * (bet.coinValue || 100)) / 100;
  }
  return ((bet.coinAmount || 0) * (bet.coinValue || 100) * 20) / 100;
};

export const normalizePosition = (length: number, position: number): number => {
  return (length + position) % length;
};
export const getIconById = (icons: Array<Icon>, id: string): Icon => {
  const result = icons.find((icon) => icon.id === id);
  if (result) {
    return result;
  }
  const error = new Error(`NO SUCH ICON FOR ID ${id}`);
  SentryRaven.captureException<Error>(error);
  throw error;
};
export const getCascadeColumns = ({
  reelPositions,
  layout,
  cascades,
}: {
  reelPositions: number[];
  layout: SlotId[][];
  cascades: {
    multiplier: number;
    winPositions: number[][];
    winAmounts: number[];
  }[];
}): Array<Array<Icon>> => {
  const { icons } = setSlotConfig();
  const moreSlots = [0, 0, 0, 0, 0, 0];
  cascades.forEach((cascade) => {
    const paylines = cascade.winPositions.reduce((res, current) => {
      return [...res, ...current];
    }, []);
    paylines.forEach((payline) => {
      moreSlots[payline % 6] += 1;
    });
  });
  const res = reelPositions.map((position, index) => {
    const start = position + 5;
    const slots: Icon[] = [];
    for (let i = 0; i < moreSlots[index] + 6; i++) {
      const position = normalizePosition(layout[index].length, start - i);
      slots.push(icons.find((icon) => icon.id === layout[index][position])!);
    }
    return slots.reverse();
  });
  return res;
};
export const getSpinResult = ({
  reelPositions,
  reelSet,
  icons,
}: {
  reelPositions: number[];
  reelSet: ReelSet;
  icons: Array<Icon>;
}): Icon[] => {
  const cols = 6;
  const rows = 6;

  return [...Array(cols * rows)].map((_, index) => {
    const row = Math.floor(index / cols);
    const column = index % cols;
    const layout = reelSet.layout[column];

    const initialCoord = reelPositions[column];
    const coord = initialCoord + row;

    return (
      icons.find(
        (icon) => icon.id === reelSet.layout[column][normalize(coord, layout)],
      ) || icons[0]
    );
  });

  const spinResult: Icon[] = [];
  for (let i = 0; i < 6; i++) {
    for (let j = 0; j < 6; j++) {
      spinResult.push(icons[Math.floor(Math.random() * icons.length)]);
    }
  }
  return spinResult;
};

export const saveReelPosition = (reelPositions: number[]): void => {
  const positions = reelPositions.toString();
  localStorage.setItem('positions', btoa(positions));
};

export const calcPercentage = (
  initialValue: number,
  percent: number,
): number => {
  return (initialValue / 100) * percent;
};

export const canPressSpin = ({
  gameMode,
  isFreeSpinsWin,
  bonusCurrentRound,
  isSpinInProgress,
  isSlotBusy,
  isSlotStopped,
  isBuyFeaturePopupOpened,
  isPopupOpened,
  transitionStarted,
}: {
  gameMode: GameMode;
  isFreeSpinsWin: boolean;
  bonusCurrentRound: number;
  isSpinInProgress: boolean;
  isSlotBusy: boolean;
  isSlotStopped: boolean;
  isBuyFeaturePopupOpened: boolean;
  isPopupOpened: boolean;
  transitionStarted: boolean;
}): boolean => {
  if (transitionStarted) {
    return false;
  }
  if (
    (isRegularMode(gameMode) || isBuyFeatureMode(gameMode)) &&
    isFreeSpinsWin
  ) {
    return false;
  }

  if (isFreeSpinMode(gameMode) && !isSlotBusy) {
    return false;
  }

  if (isPopupOpened) {
    return false;
  }

  if (isSpinInProgress && isSlotStopped) {
    return false;
  }

  if (isBuyFeaturePopupOpened) {
    return false;
  }

  return true;
};

export const dropShadowFilter = (
  options: Partial<DropShadowFilterOptions>,
): PIXI.Filter => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  return new DropShadowFilter(options) as PIXI.Filter;
};
export const isMultipliersAvailable = (mode: GameMode): boolean => {
  return mode === GameMode.DEVIL || mode === GameMode.ANGEL_AND_DEVIL;
};
export const delayedAction = (
  delay: number,
  completeCallback: () => void,
  startCallback?: () => void,
): void => {
  const delayAnim = Tween.createDelayAnimation(delay);
  if (startCallback) {
    delayAnim.addOnStart(startCallback);
  }
  delayAnim.addOnComplete(completeCallback);
  delayAnim.start();
};

export const getStateByMode = (mode: GameMode): SlotMachineState => {
  if (mode === GameMode.ANGEL) return SlotMachineState.ANGEL_FS_START;
  if (mode === GameMode.DEVIL) return SlotMachineState.DEVIL_FS_START;
  if (mode === GameMode.ANGEL_AND_DEVIL)
    return SlotMachineState.ANGEL_AND_DEVIL_FS_START;

  return SlotMachineState.IDLE;
};

export const getGameModeByBonusId = (bonusId: string): GameMode => {
  if (bonusId === bonusesId[GameMode.FREE_ROUND_BONUS]) return GameMode.FREE_ROUND_BONUS;
  if (bonusId === bonusesId[GameMode.ANGEL]) return GameMode.ANGEL;
  if (bonusId === bonusesId[GameMode.DEVIL]) return GameMode.DEVIL;
  if (bonusId === bonusesId[GameMode.ANGEL_AND_DEVIL])
    return GameMode.ANGEL_AND_DEVIL;
  if (bonusId === bonusesId[GameMode.BUY_FEATURE_ANGEL])
    return GameMode.BUY_FEATURE_ANGEL;
  if (bonusId === bonusesId[GameMode.BUY_FEATURE_DEVIL])
    return GameMode.BUY_FEATURE_DEVIL;
  if (bonusId === bonusesId[GameMode.BUY_FEATURE_ANGEL_AND_DEVIL])
    return GameMode.BUY_FEATURE_ANGEL_AND_DEVIL;
  return GameMode.REGULAR;
};

export const isHighPaySymbols = (
  spinResult: Icon[],
  positions: number[],
): boolean => {
  return positions.some((position) => {
    return HIGH_PAYING_SYMBOLS.includes(spinResult[position].id);
  });
};

export const isPlayableSlot = (index: number, mode: GameMode): boolean => {
  if (mode !== GameMode.ANGEL && mode !== GameMode.ANGEL_AND_DEVIL) {
    return index < 5;
  }
  return true;
};

export const isPlayableSlotBySpinResult = (
  index: number,
  mode: GameMode,
): boolean => {
  if (mode !== GameMode.ANGEL && mode !== GameMode.ANGEL_AND_DEVIL) {
    return index >= 6 && index < 36;
  }
  return index < 36;
};

export const getAnticipationPositions = (
  spinResult: Icon[],
  mode: GameMode,
): number[] => {
  const res: number[] = [];
  ANTICIPATION_SYMBOLS_ID.forEach((symbolId, index) => {
    const amount = ANTICIPATION_SYMBOLS_AMOUNT[index];
    if (
      spinResult
        .filter((icon, index) => isPlayableSlotBySpinResult(index, mode))
        .filter((icon) => {
          return icon.id === symbolId;
        }).length >= amount
    ) {
      spinResult.forEach((i, position) => {
        if (i.id === symbolId && isPlayableSlotBySpinResult(position, mode))
          res.push(position);
      });
    }
  });
  return res;
};

export const getBackgroundLoopAudioByGameMode = (mode: GameMode): ISongs => {
  if (mode === GameMode.ANGEL) return ISongs.Angel_BGM_Loop;
  if (mode === GameMode.DEVIL) return ISongs.DEVIL_BGM_Loop;
  if (mode === GameMode.ANGEL_AND_DEVIL) return ISongs.AandD_BGM_Loop;

  return ISongs.BaseGameBGM_Base;
};
export const getBackgroundIntroAudioByGameMode = (mode: GameMode): ISongs => {
  if (mode === GameMode.ANGEL) return ISongs.Angel_BGM_Intro;
  if (mode === GameMode.DEVIL) return ISongs.DEVIL_BGM_Intro;
  return ISongs.AandD_BGM_Intro;
};

export const getPlayListOnBroken = (isDuringBigWinLoop: boolean): Play[] => {
  if (isDuringBigWinLoop) {
    return [
      { type: ISongs.BigWin_Loop, volume: 0 },
      { type: ISongs.BigWin_Loop },
    ];
  }
  return [{ type: ISongs.BigWin_Loop }];
};
export const handleChangeRestriction = (): void => {
  if (setBrokenGame()) {
    setIsSuspended(false);
    AudioHowl.unSuspend();
    AudioHowl.changeRestriction(false, []);
    eventManager.emit(EventTypes.HANDLE_CHANGE_RESTRICTION);
  } else {
    AudioHowl.changeRestriction(false, [
      { type: ISongs.BaseGameBGM_Base },
      { type: ISongs.BaseGameBGM_Melo, volume: 0 },
    ]);
  }
};
export const handleBackgroundAudio = (
  prevMode: GameMode,
  mode: GameMode,
): void => {
  if (
    mode === GameMode.BUY_FEATURE_DEVIL ||
    mode === GameMode.BUY_FEATURE_ANGEL_AND_DEVIL ||
    mode === GameMode.BUY_FEATURE_ANGEL
  ) {
    return;
  }
  if (
    prevMode === GameMode.REGULAR ||
    prevMode === GameMode.FREE_ROUND_BONUS ||
    prevMode === GameMode.BUY_FEATURE_DEVIL ||
    prevMode === GameMode.BUY_FEATURE_ANGEL_AND_DEVIL ||
    prevMode === GameMode.BUY_FEATURE_ANGEL
  ) {
    AudioHowl.stop({ type: ISongs.BaseGameBGM_Base });
    AudioHowl.stop({ type: ISongs.BaseGameBGM_Melo });
  }
  if (prevMode === GameMode.ANGEL) {
    AudioHowl.stop({ type: ISongs.Angel_BGM_Intro });
    AudioHowl.stop({ type: ISongs.Angel_BGM_Loop });
  }
  if (prevMode === GameMode.DEVIL) {
    AudioHowl.stop({ type: ISongs.DEVIL_BGM_Intro });
    AudioHowl.stop({ type: ISongs.DEVIL_BGM_Loop });
  }
  if (prevMode === GameMode.ANGEL_AND_DEVIL) {
    AudioHowl.stop({ type: ISongs.AandD_BGM_Intro });
    AudioHowl.stop({ type: ISongs.AandD_BGM_Loop });
  }
  if (isRegularMode(mode)) {
    AudioHowl.play({ type: ISongs.BaseGameBGM_Base });
    AudioHowl.play({ type: ISongs.BaseGameBGM_Melo });
  }
  if (mode === GameMode.ANGEL) {
    AudioHowl.play({ type: ISongs.Angel_BGM_Intro });
    AudioHowl.play({ type: ISongs.Angel_BGM_Loop });
  }
  if (mode === GameMode.DEVIL) {
    AudioHowl.play({ type: ISongs.DEVIL_BGM_Intro });
    AudioHowl.play({ type: ISongs.DEVIL_BGM_Loop });
  }
  if (mode === GameMode.ANGEL_AND_DEVIL) {
    AudioHowl.play({ type: ISongs.AandD_BGM_Intro });
    AudioHowl.play({ type: ISongs.AandD_BGM_Loop });
  }
};
