import i18n from 'i18next';
import { Spine } from 'pixi-spine';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, MAPPED_SYMBOLS_LAND_ANIMATIONS, SlotId } from '../../config';
import { EventTypes } from '../../global.d';
import {
  setCurrentIsTurboSpin,
  setGameMode,
  setIsRevokeThrowingError,
  setIsTurboSpin,
  setSlotConfig,
  setStressful,
} from '../../gql/cache';
import { getCascadeColumns, isAngelSlotId, isPlayableSlot, isScatter, nextTick } from '../../utils';
import Animation from '../animations/animation';
import AnimationChain from '../animations/animationChain';
import AnimationGroup from '../animations/animationGroup';
import { CallbackPriority, TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  BASE_APPEARING_DURATION,
  DELAY_BETWEEN_REELS,
  FORCE_STOP_CASCADE_ANIMATION_DURATION,
  FORCE_STOP_CASCADE_PER_EACH_DURATION,
  REELS_AMOUNT,
  REEL_WIDTH,
  RESET_ANIMATION_BASE_DURATION,
  RESET_ANIMATION_TURBO_DURATION,
  ReelState,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOTS_PER_REEL_AMOUNT,
  SLOT_HEIGHT,
  TURBO_APPEARING_DURATION,
  eventManager,
} from '../config';
import { Icon } from '../d';
import Slot from '../slot/slot';

import Reel from './reel';

class ReelsContainer extends ViewContainer {
  public reels: Reel[] = [];

  public landedReels: number[] = [];

  private landingContainer: ViewContainer = new ViewContainer();

  private isSoundPlayed = false;

  private isForceStopped = false;

  constructor(reels: SlotId[][], startPosition: number[]) {
    super();
    this.initContainer();
    this.initReels(reels, startPosition);
    this.addChild(this.landingContainer);
    eventManager.addListener(EventTypes.SET_SLOTS_VISIBILITY, this.setSlotsVisibility.bind(this));
    eventManager.addListener(EventTypes.SETUP_REEL_POSITIONS, this.setupAnimationTarget.bind(this));
    eventManager.addListener(EventTypes.FORCE_STOP_REELS, this.forceStopReels.bind(this));
    eventManager.addListener(EventTypes.ROLLBACK_REELS, this.rollbackReels.bind(this));
    eventManager.addListener(EventTypes.REEL_LANDED, this.checkLandedReels.bind(this));
    eventManager.addListener(EventTypes.START_SPIN_ANIMATION, () => (this.isForceStopped = false));
    eventManager.addListener(EventTypes.END_WAITING_ANIMATION, () => {
      this.reels.forEach((reel: Reel) => {
        reel.cascadeAnimation?.getWaiting().cleanUpOnComplete();
      });
    });
    this.sortableChildren = true;
  }

  private checkLandedReels(id: number): void {
    this.landedReels.push(id);
    if (this.landedReels.length === 6) {
      this.landedReels = [];
      eventManager.emit(EventTypes.REELS_STOPPED);
    }
  }

  public getCurrentSpinResult(): Icon[] {
    const spinResult: Icon[] = [];
    for (let j = 0; j < SLOTS_PER_REEL_AMOUNT; j++) {
      for (let i = 0; i < REELS_AMOUNT; i++) {
        spinResult.push(
          setSlotConfig().icons.find((icon) => {
            const slot = this.reels[i].slots.find((slot) => slot.id === SLOTS_PER_REEL_AMOUNT - j - 1);
            if (!slot) {
              if (!setIsRevokeThrowingError()) {
                setIsRevokeThrowingError(true);
                setStressful({
                  show: true,
                  type: 'network',
                  message: i18n.t('errors.UNKNOWN.UNKNOWN'),
                });
              }
              return null;
            }
            return icon.id === slot.slotId;
          })!,
        );
      }
    }
    return spinResult;
  }

  public createResetReelsAnimation(winPositions: number[][]): Animation {
    const cascade = winPositions.reduce((res, current) => {
      return [...res, ...current];
    }, []);
    const sortedCascade = cascade.sort();
    sortedCascade.forEach((elem) => {
      const reel = this.reels[elem % REELS_AMOUNT];
      const index = reel.slots.findIndex((slot) => slot.id === 5 - Math.floor(elem / REELS_AMOUNT));
      reel.slots.splice(index, 1).forEach((slot) => reel.container.removeChild(slot));
    });
    this.reels.forEach((reel) => {
      reel.slots.forEach((slot, index) => {
        if (slot.id !== reel.slots.length - index - 1) slot.id = reel.slots.length - index - 1;
      });
    });
    const animation = new AnimationGroup();
    this.reels.forEach((reel, reelIndex) => {
      const chain = new AnimationChain();
      chain.appendAnimation(Tween.createDelayAnimation(reelIndex * 20));
      const group = new AnimationGroup();
      reel.slots.forEach((slot) => {
        if (slot.y !== (SLOTS_PER_REEL_AMOUNT - slot.id - 0.5) * SLOT_HEIGHT) {
          const propertyBeginValue = slot.y;
          const target = (SLOTS_PER_REEL_AMOUNT - slot.id - 0.5) * SLOT_HEIGHT;
          group.addAnimation(
            new Tween({
              object: slot,
              property: TweenProperties.Y,
              propertyBeginValue,
              target,
              duration: setIsTurboSpin() ? RESET_ANIMATION_TURBO_DURATION : RESET_ANIMATION_BASE_DURATION,
            }),
          );
        }
      });
      chain.appendAnimation(group);
      animation.addAnimation(chain);
    });
    return animation;
  }

  private rollbackReels(): void {
    for (let i = 0; i < REELS_AMOUNT; i++) {
      this.reels[i].cascadeAnimation?.getDisappearing().end();
      this.reels[i].cascadeAnimation?.getWaiting().end();
      this.reels[i].slots.forEach((slot, id) => {
        slot.y = (id + 0.5) * SLOT_HEIGHT;
      });
    }
  }

  private initContainer(): void {
    this.width = SLOTS_CONTAINER_WIDTH;
    this.height = SLOTS_CONTAINER_HEIGHT;
  }

  private initReels(reels: SlotId[][], startPosition: number[]): void {
    const cascades = getCascadeColumns({
      reelPositions: startPosition,
      layout: reels,
      cascades: [],
    });
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const position = startPosition ? startPosition[i] : 0;
      const reel = new Reel(i, reels[i], position, cascades[i]);
      this.reels[i] = reel;
      this.addChild(reel.container);
    }
  }

  private forceStopReels(): void {
    this.isForceStopped = true;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const appearingAnimations = this.reels[i].cascadeAnimation!.getAppearingAnimations();
      const delayAnim = this.reels[i].cascadeAnimation!.getAppearingDelays();
      delayAnim.duration = FORCE_STOP_CASCADE_PER_EACH_DURATION;
      appearingAnimations.forEach((animation, index) => {
        animation.duration = FORCE_STOP_CASCADE_ANIMATION_DURATION;
      });
    }
  }

  private setupAnimationTarget(layout: Array<Array<Icon>>, isStopped: boolean): void {
    const isTurboSpin: boolean = setCurrentIsTurboSpin();
    this.isSoundPlayed = false;
    for (let j = 0; j < this.reels.length; j++) {
      const reel = this.reels[j];
      const appearingChain = new AnimationChain();
      if (!isTurboSpin && !isStopped) {
        appearingChain.appendAnimation(Tween.createDelayAnimation(j * DELAY_BETWEEN_REELS));
      }
      const appearingAnimation = new AnimationGroup();
      appearingChain.appendAnimation(appearingAnimation);
      reel.cascadeAnimation?.appendAnimation(appearingChain);
      const waitingAnimation = reel.cascadeAnimation!.getWaiting();
      // Edge case when you switch tabs after you click Spin and result is not here.
      // and you come back to tab after waiting animation duration is finished.
      if (waitingAnimation.ended) {
        waitingAnimation.duration = 1;
        waitingAnimation.start();
      }

      waitingAnimation.addOnComplete(() => {
        const column = layout[j];
        reel.createSlots(column.map((icon) => icon.id));
        for (let i = 0; i < reel.slots.length; i++) {
          const slot = reel.slots[reel.slots.length - i - 1];
          const target = (SLOTS_PER_REEL_AMOUNT - i - 0.5) * SLOT_HEIGHT;
          const propertyBeginValue = (SLOTS_PER_REEL_AMOUNT - i - 7) * SLOT_HEIGHT;
          slot.y = propertyBeginValue;
          const baseDuration = isTurboSpin ? TURBO_APPEARING_DURATION : BASE_APPEARING_DURATION;
          const appearing = new Tween({
            object: slot,
            property: TweenProperties.Y,
            propertyBeginValue,
            target,
            duration: isStopped ? FORCE_STOP_CASCADE_ANIMATION_DURATION : baseDuration,
          });
          appearing.addOnComplete(() => {
            if (slot.id < SLOTS_PER_REEL_AMOUNT) {
              slot.onSlotStopped();
              this.createLandAnimation(slot, j);
            }
          }, CallbackPriority.HIGH);
          appearingAnimation.addAnimation(appearing);
        }
        appearingAnimation.addOnStart(() => {
          reel.changeState(ReelState.APPEARING);
        });
        appearingAnimation.addOnComplete(() => {
          if (isTurboSpin || this.isForceStopped || isStopped) {
            const scatter = this.reels[j].slots
              .filter((slot) => isPlayableSlot(slot.id, setGameMode()))
              .find((slot) => isScatter(slot.slotId));
            if (scatter !== undefined) {
              this.isSoundPlayed = true;
              AudioHowl.play({
                type: isAngelSlotId(scatter.slotId) ? ISongs.Scatter1Drop : ISongs.Scatter2Drop,
              });
            }
            if (j === 5 && !this.isSoundPlayed) {
              AudioHowl.play({ type: ISongs.SFX_UI_SpinStop, stopPrev: true });
            }
          } else {
            const scatter = this.reels[j].slots.find((slot) => isScatter(slot.slotId));
            if (scatter !== undefined && isPlayableSlot(scatter.id, setGameMode())) {
              AudioHowl.play({
                type: isAngelSlotId(scatter.slotId) ? ISongs.Scatter1Drop : ISongs.Scatter2Drop,
              });
            } else {
              AudioHowl.play({ type: ISongs.SFX_UI_SpinStop, stopPrev: true });
            }
          }
          reel.changeState(ReelState.IDLE);
        });
      });

      waitingAnimation.end();
    }
  }

  private createLandAnimation(slot: Slot, col: number): void {
    const src = MAPPED_SYMBOLS_LAND_ANIMATIONS[slot.slotId].src!;
    const landAnimation = new Spine(PIXI.Loader.shared.resources[src].spineData!);
    this.landingContainer.addChild(landAnimation);
    landAnimation.x = (col + 0.5) * REEL_WIDTH;
    landAnimation.y = (SLOTS_PER_REEL_AMOUNT - slot.id - 0.5) * SLOT_HEIGHT;
    slot.visible = false;

    landAnimation.state.addListener({
      complete: () => {
        nextTick(() => {
          if (slot.id === 5) {
            eventManager.emit(EventTypes.REEL_LANDED, col);
          }
          landAnimation.destroy();
          slot.visible = true;
        });
      },
    });
    landAnimation.state.setAnimation(0, MAPPED_SYMBOLS_LAND_ANIMATIONS[slot.slotId].animation!, false);
  }

  private setSlotsVisibility(slots: number[], visibility: boolean): void {
    slots.forEach((slotId) => {
      const x = slotId % REELS_AMOUNT;
      const y = Math.floor(slotId / REELS_AMOUNT);
      const slot = this.reels[x].slots.find((slot) => slot.id === 5 - y);
      if (slot) slot.visible = visibility;
    });
  }
}

export default ReelsContainer;
