import { EventTypes } from '../../global.d';
import { setIsTurboSpin } from '../../gql/cache';
import { calcPercentage, isMobileDevice } from '../../utils';
import { eventManager } from '../config';

import { ControlButton } from './index';

class TurboSpinBtn extends ControlButton {
  constructor() {
    super('turbospin', 40);
    this.updateIntent(setIsTurboSpin() ? 'turbospin' : 'turbospin-off');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.visible = !isMobileDevice();
    this.initSubscriptions();
  }

  private initSubscriptions = (): void => {
    eventManager.on(EventTypes.TOGGLE_TURBO_SPIN, (isTurboSpin: boolean) => {
      this.updateIntent(isTurboSpin ? 'turbospin' : 'turbospin-off');
    });
  };

  private handleClick = (): void => {
    setIsTurboSpin(!setIsTurboSpin());
    this.updateIntent(setIsTurboSpin() ? 'turbospin' : 'turbospin-off');
  };

  public handlePosition(): void {
    const x = calcPercentage(this.applicationSize.width, 6.3);
    const y = calcPercentage(this.applicationSize.height, 95.7);
    this.setSize(calcPercentage(this.applicationSize.width, 2.7));

    this.x = x;
    this.y = y;
  }
}

export default TurboSpinBtn;
