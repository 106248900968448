import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs, MAPPED_SYMBOLS, SlotId } from '../../config';
import { setGameMode } from '../../gql/cache';
import { getFromMappedSymbol, isAngelSlotId, isPlayableSlot, isScatter } from '../../utils';
import { SCATTER_SLOT_HEIGHT, SCATTER_SLOT_WIDTH, SLOTS_PER_REEL_AMOUNT, SLOT_HEIGHT, SLOT_WIDTH } from '../config';

class Slot extends PIXI.Sprite {
  public id: number;

  public slotId: SlotId;

  public textureSlotId: SlotId;

  constructor(id: number, slotId: SlotId) {
    super(PIXI.Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId)));
    this.id = id;
    this.slotId = slotId;
    this.textureSlotId = slotId;
    this.width = isScatter(slotId) ? SCATTER_SLOT_WIDTH : SLOT_WIDTH;
    this.height = isScatter(slotId) ? SCATTER_SLOT_HEIGHT : SLOT_HEIGHT;
    this.anchor.set(0.5, 0.5);
    this.y = (SLOTS_PER_REEL_AMOUNT - id - 0.5) * SLOT_HEIGHT;
    this.x = SLOT_WIDTH / 2;
    this.zIndex = isScatter(slotId) ? 2 : 1;
  }

  public changeTexture(slotId: SlotId): void {
    this.texture = PIXI.Texture.from(getFromMappedSymbol(MAPPED_SYMBOLS, slotId));
    this.textureSlotId = slotId;
  }

  public onSlotStopped(): void {
    // todo add sound/animation on slot stop
  }
}

export default Slot;
