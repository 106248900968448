import { useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { CSSProperties, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { setSlotConfig } from '../../gql/cache';
import { ISlotConfig } from '../../gql/d';
import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

export interface MyCustomCSS extends CSSProperties {
  'container-type': string | number;
}

const MysterySymbolFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFeatureDescs = [
    { key: 'infoFeatureDesc_1', text: i18n.t('infoFeatureDesc_1') },
    { key: 'infoFeatureDesc_2', text: i18n.t('infoFeatureDesc_2') },
    { key: 'infoFeatureDesc_3', text: i18n.t('infoFeatureDesc_3') },
    { key: 'infoFeatureDesc_4', text: i18n.t('infoFeatureDesc_4') },
    { key: 'infoFeatureDesc_5', text: i18n.t('infoFeatureDesc_5') },
  ];

  return (
    <section className={styles.feature}>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const FreeSpinFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoCascadeFeatureDescs = [
    { key: 'infoCascadeFeatureDesc_1', text: i18n.t('infoCascadeFeatureDesc_1') },
    { key: 'infoCascadeFeatureDesc_2', text: i18n.t('infoCascadeFeatureDesc_2') },
    { key: 'infoCascadeFeatureDesc_3', text: i18n.t('infoCascadeFeatureDesc_3') },
    { key: 'infoCascadeFeatureDesc_4', text: i18n.t('infoCascadeFeatureDesc_4') },
    { key: 'infoCascadeFeatureDesc_5', text: i18n.t('infoCascadeFeatureDesc_5') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoCascadeFeatureTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoCascadeFeatureDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const FreeSpins: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFreeSpinsDescs = [
    { key: 'infoFreeSpinsDescs_1', text: i18n.t('infoFreeSpinsDescs_1') },
    { key: 'infoFreeSpinsDescs_2', text: i18n.t('infoFreeSpinsDescs_2') },
    { key: 'infoFreeSpinsDescs_3', text: i18n.t('infoFreeSpinsDescs_3') },
    { key: 'infoFreeSpinsDescs_4', text: i18n.t('infoFreeSpinsDescs_4') },
  ];
  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFreeSpinsTitle')}</h1>
      <div className={styles.fig}>
        <div className={styles.fig__text} />
      </div>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFreeSpinsDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const AngelFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoAngelFeatureDescs = [
    { key: 'infoAngelFeatureDescs_1', text: i18n.t('infoAngelFeatureDescs_1') },
    { key: 'infoAngelFeatureDescs_2', text: i18n.t('infoAngelFeatureDescs_2') },
    { key: 'infoAngelFeatureDescs_3', text: i18n.t('infoAngelFeatureDescs_3') },
    { key: 'infoAngelFeatureDescs_4', text: i18n.t('infoAngelFeatureDescs_4') },
    { key: 'infoAngelFeatureDescs_5', text: i18n.t('infoAngelFeatureDescs_5') },
  ];
  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoAngelFeatureTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoAngelFeatureDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const DevilFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoDevilFeatureDescs = [
    { key: 'infoDevilFeatureDescs_1', text: i18n.t('infoDevilFeatureDescs_1') },
    { key: 'infoDevilFeatureDescs_2', text: i18n.t('infoDevilFeatureDescs_2') },
    { key: 'infoDevilFeatureDescs_3', text: i18n.t('infoDevilFeatureDescs_3') },
    { key: 'infoDevilFeatureDescs_4', text: i18n.t('infoDevilFeatureDescs_4') },
    { key: 'infoDevilFeatureDescs_5', text: i18n.t('infoDevilFeatureDescs_5') },
    { key: 'infoDevilFeatureDescs_6', text: i18n.t('infoDevilFeatureDescs_6') },
    { key: 'infoDevilFeatureDescs_7', text: i18n.t('infoDevilFeatureDescs_7') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoDevilFeatureTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoDevilFeatureDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const AngelAndDevilFeature: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoAngelAndDevilDescs = [
    { key: 'infoAngelAndDevilDescs_1', text: i18n.t('infoAngelAndDevilDescs_1') },
    { key: 'infoAngelAndDevilDescs_2', text: i18n.t('infoAngelAndDevilDescs_2') },
    { key: 'infoAngelAndDevilDescs_3', text: i18n.t('infoAngelAndDevilDescs_3') },
    { key: 'infoAngelAndDevilDescs_4', text: i18n.t('infoAngelAndDevilDescs_4') },
    { key: 'infoAngelAndDevilDescs_5', text: i18n.t('infoAngelAndDevilDescs_5') },
    { key: 'infoAngelAndDevilDescs_6', text: i18n.t('infoAngelAndDevilDescs_6') },
    { key: 'infoAngelAndDevilDescs_7', text: i18n.t('infoAngelAndDevilDescs_7') },
    { key: 'infoAngelAndDevilDescs_8', text: i18n.t('infoAngelAndDevilDescs_8') },
  ];

  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoAngelAndDevilTitle')}</h1>
      <div className={`${styles.p} ${styles.left}`}>
        {infoAngelAndDevilDescs.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const FeaturesInfo: React.VFC = () => {
  const { t } = useTranslation();
  return (
    <section className={styles.feature}>
      <div
        className={`${styles.p} ${styles.left} ${styles.margin}`}
        dangerouslySetInnerHTML={{ __html: t('infoFeature1') }}
      />
      <div className={`${styles.p} ${styles.left}`} dangerouslySetInnerHTML={{ __html: t('infoFeature2') }} />
    </section>
  );
};

const BuyFeatureFunction: React.VFC = () => {
  const i18n = useTranslation();
  const { t } = i18n;

  const infoFeatureBuy = [
    { key: 'infoFeatureBuyDesc_1', text: i18n.t('infoFeatureBuyDesc_1') },
    { key: 'infoFeatureBuyDesc_2', text: i18n.t('infoFeatureBuyDesc_2') },
    { key: 'infoFeatureBuyDesc_3', text: i18n.t('infoFeatureBuyDesc_3') },
    { key: 'infoFeatureBuyDesc_4', text: i18n.t('infoFeatureBuyDesc_4') },
    { key: 'infoFeatureBuyDesc_5', text: i18n.t('infoFeatureBuyDesc_5') },
    { key: 'infoFeatureBuyDesc_6', text: i18n.t('infoFeatureBuyDesc_6') },
    { key: 'infoFeatureBuyDesc_7', text: i18n.t('infoFeatureBuyDesc_7') },
    { key: 'infoFeatureBuyDesc_8', text: i18n.t('infoFeatureBuyDesc_8') },
  ];

  if (!setSlotConfig().isBuyFeatureEnabled) {
    return null;
  }
  return (
    <section className={styles.feature}>
      <h1 className={styles.title}>{t('infoFeatureBuyTitle')}</h1>
      <div
        className={styles.figcontainer}
        style={{ backgroundImage: `url('${Resources.getSource(ResourceTypes.buyFeatureBtn)}')` } as MyCustomCSS}
      >
        <p className={`${styles.featuretext}`} dangerouslySetInnerHTML={{ __html: t('buyFeatureBtn') }} />
      </div>
      <div className={`${styles.p} ${styles.left}`}>
        {infoFeatureBuy.map((o) => (
          <div key={o.key} dangerouslySetInnerHTML={{ __html: o.text }} />
        ))}
      </div>
    </section>
  );
};

const Features: React.VFC = () => {
  const { isBuyFeatureEnabled } = useReactiveVar<ISlotConfig>(setSlotConfig);

  return (
    <div className={styles.gameRules}>
      <MysterySymbolFeature />
      <FreeSpinFeature />
      {isBuyFeatureEnabled ? <FreeSpins /> : ''}
      <AngelFeature />
      <DevilFeature />
      <AngelAndDevilFeature />
      <FeaturesInfo />
      <BuyFeatureFunction />
    </div>
  );
};

export default Features;
function t(arg0: string): string {
  throw new Error('Function not implemented.');
}
