import i18n from 'i18next';
import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

import { EventTypes, FreeSpinsTitleProps, GameMode } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { getFreeSpinsTitleByGameMode, getGameModeByBonusId, isRegularMode, updateTextScale } from '../../utils';
import Animation from '../animations/animation';
import { createZoomAnimation } from '../animations/helper';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

import {
  FREE_SPINS_TEXT_X,
  FREE_SPINS_TEXT_Y,
  FREE_SPINS_TITLE_POSITION_X,
  FREE_SPINS_TITLE_POSITION_Y,
  FREE_SPIN_ANIMATION_DELAY,
  FREE_SPIN_ANIMATION_LOOP,
  FREE_SPIN_ANIMATION_SCALE,
  FREE_SPIN_ICON_X,
  FREE_SPIN_ICON_Y,
  FREE_SPIN_INIT_WIDTH_A,
  FREE_SPIN_INIT_WIDTH_AD,
  FREE_SPIN_SPINS_TEXT_WIDTH,
  FREE_SPIN_TEXT_FRAME_WIDTH_A,
  FREE_SPIN_TEXT_FRAME_WIDTH_AD,
  FREE_SPIN_TEXT_FRAME_X,
  FREE_SPIN_TEXT_FRAME_Y,
  FREE_SPIN_TITLE_TEXT_HEIGHT,
  FREE_SPIN_TITLE_TEXT_WIDTH,
} from './config';
import { spinsStyleAngel, spinsStyleAngelandDevil, spinsStyleDevil, textStyle } from './textStyles';

class FreeSpinsTitle extends ViewContainer {
  public text: PIXI.Text;

  private spins: PIXI.Text;

  private pulsAnimation: Animation | null = null;

  private spinsAmount: string;

  private currentSpin: string;

  private textFrame: PIXI.Sprite;

  private textIcon: PIXI.Sprite;

  private angelFrameTexture: PIXI.Texture = PIXI.Texture.from(ResourceTypes.angelMultiplayerFrame);

  private devilFrameTexture: PIXI.Texture = PIXI.Texture.from(ResourceTypes.devilMultiplayerFrame);

  private angelAndDevilFrameTexture: PIXI.Texture = PIXI.Texture.from(ResourceTypes.angelDevilMultiplayerFrame);

  private angelIconTexture: PIXI.Texture = PIXI.Texture.from(ResourceTypes.angelMultiplayerIcon);

  private devilIconTexture: PIXI.Texture = PIXI.Texture.from(ResourceTypes.devilMultiplayerIcon);

  private angelAndDevilIconTexture: PIXI.Texture = PIXI.Texture.from(ResourceTypes.angelDevilMultiplayerIcon);

  constructor(props: FreeSpinsTitleProps) {
    super();
    const currentGameMode = setBrokenGame() ? getGameModeByBonusId(setCurrentBonus().bonusId) : setGameMode();
    this.y = FREE_SPINS_TITLE_POSITION_Y;
    this.text = this.initText(props.text);
    this.spins = this.initSpins(currentGameMode, props.spins, props.currentSpin);
    this.textFrame = this.initFrame(currentGameMode);
    this.textFrame.x = FREE_SPIN_TEXT_FRAME_X;
    this.textFrame.y = FREE_SPIN_TEXT_FRAME_Y;
    this.textFrame.width =
      currentGameMode === GameMode.ANGEL_AND_DEVIL ? FREE_SPIN_TEXT_FRAME_WIDTH_AD : FREE_SPIN_TEXT_FRAME_WIDTH_A;
    this.textIcon = this.initIcon(currentGameMode);
    this.textIcon.x = FREE_SPIN_ICON_X;
    this.textIcon.y = FREE_SPIN_ICON_Y;
    this.spinsAmount = props.spins;
    this.currentSpin = props.currentSpin;
    this.x = FREE_SPINS_TITLE_POSITION_X;
    this.init();
    eventManager.addListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE, (spins: string, curr: string): void =>
      this.handleUpdate(spins, curr),
    );
    eventManager.addListener(EventTypes.UPDATE_FREE_SPINS_TITLE, this.updateTitle.bind(this));
    this.visible = !isRegularMode(setGameMode());
  }

  private updateTitle(mode: GameMode): void {
    this.text.text = i18n.t(getFreeSpinsTitleByGameMode(mode));
    updateTextScale(this.text, FREE_SPIN_TITLE_TEXT_WIDTH, FREE_SPIN_TITLE_TEXT_HEIGHT);
    this.spins.style = this.getSpinsTextStyle(mode);
    this.textFrame.texture = this.getFrameTexture(mode);
    this.textFrame.width =
      mode === GameMode.ANGEL_AND_DEVIL ? FREE_SPIN_TEXT_FRAME_WIDTH_AD : FREE_SPIN_TEXT_FRAME_WIDTH_A;
    this.textIcon.texture = this.getIconTexture(mode);
    this.spins.x = setGameMode() === GameMode.ANGEL_AND_DEVIL ? FREE_SPIN_INIT_WIDTH_AD : FREE_SPIN_INIT_WIDTH_A;
  }

  private init(): void {
    this.addChild(this.textFrame);
    this.addChild(this.textIcon);
    this.addChild(this.text);
    this.addChild(this.spins);
  }

  private initText(titleText?: string): PIXI.Text {
    const text = new PIXI.Text(i18n.t(titleText || ''), textStyle as Partial<PIXI.ITextStyle>);
    text.y = FREE_SPINS_TEXT_X;
    text.x = FREE_SPINS_TEXT_Y;
    updateTextScale(text, FREE_SPIN_TITLE_TEXT_WIDTH, FREE_SPIN_TITLE_TEXT_HEIGHT);
    text.anchor.set(0.5, 0.5);

    return text;
  }

  private getFrameTexture(mode: GameMode): PIXI.Texture {
    if (mode === GameMode.ANGEL) {
      return this.angelFrameTexture;
    }
    if (mode === GameMode.DEVIL) {
      return this.devilFrameTexture;
    }
    return this.angelAndDevilFrameTexture;
  }

  private initFrame(mode: GameMode): PIXI.Sprite {
    return PIXI.Sprite.from(this.getFrameTexture(mode));
  }

  private getIconTexture(mode: GameMode): PIXI.Texture {
    if (mode === GameMode.ANGEL) {
      return this.angelIconTexture;
    }
    if (mode === GameMode.DEVIL) {
      return this.devilIconTexture;
    }
    return this.angelAndDevilIconTexture;
  }

  private initIcon(mode: GameMode): PIXI.Sprite {
    return PIXI.Sprite.from(this.getIconTexture(mode));
  }

  private getSpinsTextStyle(mode: GameMode): Partial<PIXI.ITextStyle> {
    if (mode === GameMode.ANGEL) {
      return spinsStyleAngel as Partial<PIXI.ITextStyle>;
    }
    if (mode === GameMode.DEVIL) {
      return spinsStyleDevil as Partial<PIXI.ITextStyle>;
    }
    return spinsStyleAngelandDevil as Partial<PIXI.ITextStyle>;
  }

  private initSpins(mode: GameMode, spin?: string, currentSpin?: string): PIXI.Text {
    const spins = new PIXI.Text(`${currentSpin} / ${spin}`, this.getSpinsTextStyle(mode));
    spins.y = FREE_SPINS_TEXT_X;
    spins.x = mode === GameMode.ANGEL_AND_DEVIL ? FREE_SPIN_INIT_WIDTH_AD : FREE_SPIN_INIT_WIDTH_A;
    updateTextScale(spins, FREE_SPIN_SPINS_TEXT_WIDTH, FREE_SPIN_TITLE_TEXT_HEIGHT);
    spins.anchor.set(0.5, 0.5);
    return spins;
  }

  private handleUpdate(spins?: string, currentSpin?: string): void {
    if (this.currentSpin === currentSpin && this.spinsAmount === spins) {
      return;
    }

    this.currentSpin = currentSpin ?? this.currentSpin;
    this.spinsAmount = spins ?? this.spinsAmount;

    this.spins.text = `${currentSpin || this.currentSpin} / ${spins || this.spinsAmount}`;
    updateTextScale(this.spins, FREE_SPIN_SPINS_TEXT_WIDTH, FREE_SPIN_TITLE_TEXT_HEIGHT);
    this.pulsAnimation = createZoomAnimation(
      this.spins,
      this.spins.scale.x * FREE_SPIN_ANIMATION_SCALE,
      this.spins.scale.x,
      FREE_SPIN_ANIMATION_DELAY,
      FREE_SPIN_ANIMATION_LOOP,
    );
    this.pulsAnimation?.start();
  }

  public handleDestroy(): void {
    eventManager.removeListener(EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE);
    this.destroy({ children: true, texture: true, baseTexture: true });
  }
}

export default FreeSpinsTitle;
