import React from 'react';
import { useTranslation } from 'react-i18next';

import { ResourceTypes } from '../../resources.d';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const PayLines: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.payLines}>
      <h1 className={styles.title}>{t('infoPayLinesTitle')}</h1>
      <img draggable="false" src={Resources.getSource(ResourceTypes.infoPaylines)} alt="payLines" />
      <div
        className={`${styles.p} ${styles.left} ${styles.margin}`}
        dangerouslySetInnerHTML={{ __html: t('infoPayLinesDesc1') }}
      />
      <div className={`${styles.p} ${styles.left}`} dangerouslySetInnerHTML={{ __html: t('infoPayLinesDesc2') }} />
    </div>
  );
};

export default PayLines;
