import * as PIXI from 'pixi.js';

import { EventTypes, GameMode, bonusesId } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';
import { getGameModeByBonusId } from '../../utils';
import SpineAnimation from '../animations/spine';
import ViewContainer from '../components/container';
import { eventManager } from '../config';

class Background extends ViewContainer {
  private baseBg = PIXI.Texture.from(ResourceTypes.slotBg);

  private bonusAngelBg = PIXI.Texture.from(ResourceTypes.slotBgAngel);

  private bonusDevilBg = PIXI.Texture.from(ResourceTypes.slotBgDevil);

  private bonusAngelAndDevilBg = PIXI.Texture.from(ResourceTypes.slotBgAngelAndDevil);

  private bgAmbientAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.ambient.spineData!);

  private bgSprite = new PIXI.Sprite();

  constructor() {
    super();

    this.bgSprite.texture = this.getBgTextureByBonusId(
      setBrokenGame() ? setCurrentBonus().bonusId : bonusesId[GameMode.REGULAR],
    );
    this.bgSprite.anchor.set(0.5);
    this.addChild(this.bgSprite);
    this.addChild(this.bgAmbientAnimation.spine);

    eventManager.addListener(EventTypes.RESIZE, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.changeModeBackground.bind(this));
  }

  private getBgTextureByBonusId(id: string): PIXI.Texture {
    const gameMode = getGameModeByBonusId(id);
    switch (gameMode) {
      case GameMode.ANGEL:
        this.bgAmbientAnimation.setAnimation('ambient_heaven', true);
        return this.bonusAngelBg;
      case GameMode.DEVIL:
        this.bgAmbientAnimation.setAnimation('ambient_hell', true);
        return this.bonusDevilBg;
      case GameMode.ANGEL_AND_DEVIL:
        this.bgAmbientAnimation.setAnimation('ambient_special', true);
        return this.bonusAngelAndDevilBg;
      default:
        this.bgAmbientAnimation.setAnimation('ambient_main', true);
        return this.baseBg;
    }
  }

  private changeModeBackground(settings: { mode: GameMode }) {
    switch (settings.mode) {
      case GameMode.ANGEL:
        this.bgSprite.texture = this.bonusAngelBg;
        this.bgAmbientAnimation.setAnimation('ambient_heaven', true);
        break;
      case GameMode.DEVIL:
        this.bgSprite.texture = this.bonusDevilBg;
        this.bgAmbientAnimation.setAnimation('ambient_hell', true);
        break;
      case GameMode.ANGEL_AND_DEVIL:
        this.bgSprite.texture = this.bonusAngelAndDevilBg;
        this.bgAmbientAnimation.setAnimation('ambient_special', true);
        break;
      default:
        this.bgSprite.texture = this.baseBg;
        this.bgAmbientAnimation.setAnimation('ambient_main', true);
        break;
    }
  }

  private resize(width: number, height: number): void {
    this.x = width / 2;
    this.y = height / 2;

    const bgAspectRatio = this.bgSprite.width / this.bgSprite.height;
    const aspectRatio = width / height;

    if (bgAspectRatio > aspectRatio) {
      this.scale.set(height / this.bgSprite.height);
    } else {
      this.scale.set(width / this.bgSprite.width);
    }
  }
}
export default Background;
