import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, FreeSpinsTitleProps, GameMode, MessageBannerProps } from '../../global.d';
import { setBrokenGame, setCurrentBonus, setGameMode } from '../../gql/cache';
import i18n from '../../i18next';
import { ResourceTypes } from '../../resources.d';
import {
  delayedAction,
  getFreeSpinsTitleByGameMode,
  getGameModeByBonusId,
  isFreeSpinMode,
  isRegularMode,
} from '../../utils';
import AnimationGroup from '../animations/animationGroup';
import { TweenProperties } from '../animations/d';
import SpineAnimation from '../animations/spine';
import Tween from '../animations/tween';
import ViewContainer from '../components/container';
import {
  COUNT_UP_MESSAGE_X,
  COUNT_UP_MESSAGE_Y,
  DESKTOP_REELS_FRAME_HEIGHT,
  DESKTOP_REELS_FRAME_WIDTH,
  GAME_CONTAINER_HEIGHT,
  GAME_CONTAINER_WIDTH,
  MOBILE_REELS_FRAME_HEIGHT,
  MOBILE_REELS_FRAME_WIDTH,
  MULTIPLIER_LOCK_HEIGHT,
  MULTIPLIER_LOCK_X,
  MULTIPLIER_LOCK_Y,
  MULTIPLIER_MOVE_TIME,
  MULTIPLIER_TITLE_X,
  MULTIPLIER_TITLE_Y,
  REELS_AMOUNT,
  REELS_FRAME_POSITION_X,
  REELS_FRAME_POSITION_Y,
  REEL_WIDTH,
  SLOTS_CONTAINER_HEIGHT,
  SLOTS_CONTAINER_WIDTH,
  SLOT_HEIGHT,
  SLOT_LOCKS_Y,
  eventManager,
  multiplierTitleTextStyle,
} from '../config';
import { IGameContainer } from '../d';
import FreeSpinsTitle from '../freeSpinsTitle/freeSpinsTitle';
import { FreeSpinMessageBanner } from '../messageBanner/freeSpinMessageBanner';
import MessageBanner from '../messageBanner/messageBanner';
import CoinsAnimationContainer from '../winAnimations/coinsAnimationContainer';
import WinCountUpMessage from '../winAnimations/winCountUpMessage';

import GameReplay from './gameReplay';
import GameTitle from './gameTitle';

class GameView extends ViewContainer {
  public leftLinesContainer: PIXI.Container | null = null;

  public rightLinesContainer: PIXI.Container | null = null;

  public winSlotsContainer: PIXI.Container;

  public miniPayTableContainer: PIXI.Container;

  public reelsBackgroundContainer: PIXI.Container;

  public reelsContainer: PIXI.Container;

  public slotsContainer: PIXI.Container;

  public winLabelContainer: PIXI.Container;

  public winCountUpMessage: WinCountUpMessage;

  public gameTitle: PIXI.Container;

  public gameReplay: PIXI.Container;

  public coinsAnimationContainer: PIXI.Container;

  public frame: PIXI.Sprite;

  public freeSpinsTitle: FreeSpinsTitle | undefined;

  private angelAttackSpine: SpineAnimation;

  private devilAttackSpine: SpineAnimation;

  public maskArea: PIXI.Graphics;

  private locksContainer: ViewContainer;

  private locks: SpineAnimation[] = [];

  private multiplierLock: SpineAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.locks.spineData!);

  private multiplierExplosion: SpineAnimation;

  private multiplierTitle: PIXI.Text;

  private multiplierTitleMoveSpine: SpineAnimation;

  private multiplierTitleContainer: ViewContainer;

  constructor(props: IGameContainer) {
    super();
    this.width = GAME_CONTAINER_WIDTH;
    this.height = GAME_CONTAINER_HEIGHT;
    this.sortableChildren = true;
    this.slotsContainer = new PIXI.Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.x = 0;
    this.slotsContainer.y = 130;
    this.slotsContainer.sortableChildren = true;
    this.maskArea = new PIXI.Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();
    this.initMultiplierLock();
    this.angelAttackSpine = new SpineAnimation({}, PIXI.Loader.shared.resources.angel_attack.spineData!);
    this.devilAttackSpine = new SpineAnimation({}, PIXI.Loader.shared.resources.devil_attack.spineData!);
    this.angelAttackSpine.spine.visible = false;
    this.devilAttackSpine.spine.visible = false;

    this.devilAttackSpine.spine.x = MULTIPLIER_TITLE_X;
    this.devilAttackSpine.spine.y = MULTIPLIER_TITLE_Y + 30;
    this.angelAttackSpine.spine.x = 540;
    this.angelAttackSpine.spine.y = 200;

    this.multiplierTitle = this.initMultiplierTitle();
    this.multiplierTitleMoveSpine = new SpineAnimation({}, PIXI.Loader.shared.resources.multiplierTrail.spineData!);
    this.multiplierTitleMoveSpine.spine.x = COUNT_UP_MESSAGE_X;
    this.multiplierTitleMoveSpine.spine.y = COUNT_UP_MESSAGE_Y;
    this.multiplierTitleMoveSpine.spine.visible = false;
    this.multiplierTitleContainer = new ViewContainer();
    this.multiplierTitleContainer.addChild(this.multiplierTitleMoveSpine.spine);
    this.multiplierTitleContainer.addChild(this.multiplierTitle);

    this.multiplierExplosion = this.initMultiplierExplosion();
    this.slotsContainer.interactive = true;
    this.gameTitle = new GameTitle();
    this.gameReplay = new GameReplay();
    this.coinsAnimationContainer = new CoinsAnimationContainer();
    this.winLabelContainer = props.winLabelContainer;
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.addChild(this.slotsContainer);
    this.frame = isMobile ? this.initMobileReelsFrame() : this.initDesktopReelsFrame();
    this.addChild(this.multiplierLock.spine);
    this.addChild(this.frame);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameTitle);
    this.addChild(this.gameReplay);
    this.locksContainer = this.initLocks();
    this.addChild(this.multiplierTitleContainer);
    this.addChild(this.multiplierExplosion.spine);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.coinsAnimationContainer);
    this.addChild(this.winLabelContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.angelAttackSpine.spine);
    this.addChild(this.devilAttackSpine.spine);
    if (setBrokenGame()) {
      this.createFreeSpinsTitle({
        text: i18n.t(getFreeSpinsTitleByGameMode(getGameModeByBonusId(setCurrentBonus().bonusId))),
        spins: String(setCurrentBonus().rounds),
        currentSpin: String(setCurrentBonus().currentRound),
      });
    }
    eventManager.addListener(EventTypes.START_ANGEL_ATTACK, this.startAngelAttack.bind(this));
    eventManager.addListener(EventTypes.START_DEVIL_ATTACK, this.startDevilAttack.bind(this));
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resize.bind(this));
    eventManager.addListener(EventTypes.CHANGE_MODE, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.HIDE_FRAME, () => {
      this.frame.visible = false;
    });
    eventManager.addListener(EventTypes.SHOW_FRAME, () => {
      this.frame.visible = true;
    });
    eventManager.addListener(EventTypes.MANUAL_CHANGE_BACKGROUND, this.onModeChange.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPINS_TITLE, this.createFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.REMOVE_FREE_SPINS_TITLE, this.removeFreeSpinsTitle.bind(this));
    eventManager.addListener(EventTypes.CREATE_MESSAGE_BANNER, this.createTotalWinMessage.bind(this));
    eventManager.addListener(EventTypes.CREATE_FREE_SPIN_MESSAGE_BANNER, this.createFreeSpinMessage.bind(this));
    eventManager.addListener(EventTypes.START_DESTROY_ANGEL_LOCKS, this.startDestroyAngelLocks.bind(this));
    eventManager.addListener(EventTypes.START_DESTROY_DEVIL_LOCK, this.startDestroyDevilLock.bind(this));
    eventManager.addListener(EventTypes.UPDATE_MULTIPLIER_VALUE, this.updateMultiplierValue.bind(this));
    eventManager.addListener(EventTypes.MOVE_MULTIPLIER, this.moveMultiplier.bind(this));
  }

  private initMultiplierExplosion(): SpineAnimation {
    const explosion = new SpineAnimation({}, PIXI.Loader.shared.resources.multiplierExplosion.spineData!);
    explosion.spine.x = COUNT_UP_MESSAGE_X;
    explosion.spine.y = COUNT_UP_MESSAGE_Y;
    explosion.spine.visible = false;
    return explosion;
  }

  private initMultiplierTitle(): PIXI.Text {
    const isMultiplierAvailable =
      (setBrokenGame() && getGameModeByBonusId(setCurrentBonus().bonusId) === GameMode.DEVIL) ||
      getGameModeByBonusId(setCurrentBonus().bonusId) === GameMode.ANGEL_AND_DEVIL;
    const initValue = isMultiplierAvailable ? `x${setCurrentBonus().cascadeMultiplier}` : 'x1';
    const multiplierTitle = new PIXI.Text(String(initValue), multiplierTitleTextStyle);
    multiplierTitle.x = MULTIPLIER_TITLE_X;
    multiplierTitle.y = MULTIPLIER_TITLE_Y;
    multiplierTitle.anchor.set(0.5, 0.5);
    multiplierTitle.visible = isMultiplierAvailable;
    return multiplierTitle;
  }

  private moveMultiplier(): void {
    const moveAnimation = new AnimationGroup();
    const moveXAnimation = new Tween({
      object: this.multiplierTitle,
      property: TweenProperties.X,
      propertyBeginValue: MULTIPLIER_TITLE_X,
      target: COUNT_UP_MESSAGE_X,
      duration: MULTIPLIER_MOVE_TIME,
    });
    const moveYAnimation = new Tween({
      object: this.multiplierTitle,
      property: TweenProperties.Y,
      propertyBeginValue: MULTIPLIER_TITLE_Y,
      target: COUNT_UP_MESSAGE_Y,
      duration: MULTIPLIER_MOVE_TIME,
    });
    const moveTrailXAnimation = new Tween({
      object: this.multiplierTitleMoveSpine.spine,
      property: TweenProperties.X,
      propertyBeginValue: MULTIPLIER_TITLE_X,
      target: COUNT_UP_MESSAGE_X,
      duration: MULTIPLIER_MOVE_TIME,
    });
    const moveTrailYAnimation = new Tween({
      object: this.multiplierTitleMoveSpine.spine,
      property: TweenProperties.Y,
      propertyBeginValue: MULTIPLIER_TITLE_Y,
      target: COUNT_UP_MESSAGE_Y,
      duration: MULTIPLIER_MOVE_TIME,
    });
    moveAnimation.addAnimation(moveXAnimation);
    moveAnimation.addAnimation(moveYAnimation);
    moveAnimation.addAnimation(moveTrailXAnimation);
    moveAnimation.addAnimation(moveTrailYAnimation);
    moveAnimation.addOnStart(() => {
      AudioHowl.play({ type: ISongs.Mpmove });
      delayedAction(
        1233,
        () => {
          this.multiplierTitleMoveSpine.spine.visible = false;
        },
        () => {
          this.multiplierTitleMoveSpine.setAnimation('explosion_trail', false);
          this.multiplierTitleMoveSpine.spine.visible = true;
        },
      );
    });
    moveAnimation.addOnComplete(() => {
      this.multiplierTitle.x = MULTIPLIER_TITLE_X;
      this.multiplierTitle.y = MULTIPLIER_TITLE_Y;
      delayedAction(
        1000,
        () => {
          this.multiplierExplosion.spine.visible = false;
        },
        () => {
          this.multiplierExplosion.spine.visible = true;
          this.multiplierExplosion.setAnimation('explosion', true);
        },
      );
    });
    moveAnimation.start();
  }

  private updateMultiplierValue(value: number | string): void {
    // todo add animation if any
    this.multiplierTitle.text = String(`x${value}`);
  }

  private initMultiplierLock(): void {
    this.multiplierLock.spine.height = MULTIPLIER_LOCK_HEIGHT + 100;
    this.multiplierLock.spine.x = MULTIPLIER_LOCK_X;
    this.multiplierLock.spine.y = MULTIPLIER_LOCK_Y;
    const animationName =
      setBrokenGame() &&
      (GameMode.DEVIL === getGameModeByBonusId(setCurrentBonus().bonusId) ||
        GameMode.ANGEL_AND_DEVIL === getGameModeByBonusId(setCurrentBonus().bonusId))
        ? 'unlock_multiplayer_static'
        : 'lock_multiplayer_static';
    this.multiplierLock.setAnimation(animationName, true);
  }

  private startDestroyDevilLock(): void {
    delayedAction(
      1000,
      () => {
        this.multiplierTitle.visible = true;
        this.multiplierLock.setAnimation('unlock_multiplayer_static', true);
      },
      () => {
        this.multiplierLock.setAnimation('lock_multiplayer_animation', false);
      },
    );
  }

  private startAngelAttack(): void {
    delayedAction(
      1666,
      () => {
        this.angelAttackSpine.spine.visible = false;
      },
      () => {
        AudioHowl.play({ type: ISongs.Angel_Attack, stopPrev: true });
        this.angelAttackSpine.spine.visible = true;
        this.angelAttackSpine.setAnimation('angel_attack', false);
        delayedAction(800, () => {
          AudioHowl.play({ type: ISongs.Break, stopPrev: true });
          eventManager.emit(EventTypes.START_DESTROY_ANGEL_LOCKS);
        });
      },
    );
  }

  private startDevilAttack(): void {
    delayedAction(
      1666,
      () => {
        this.devilAttackSpine.spine.visible = false;
      },
      () => {
        AudioHowl.play({ type: ISongs.Devil_Attack, stopPrev: true });
        this.devilAttackSpine.spine.visible = true;
        this.devilAttackSpine.setAnimation('devil_attack', false);
        delayedAction(800, () => {
          AudioHowl.play({ type: ISongs.Break, stopPrev: true });
          eventManager.emit(EventTypes.START_DESTROY_DEVIL_LOCK);
        });
      },
    );
  }

  private initLocks(): ViewContainer {
    const locksContainer = new ViewContainer();
    locksContainer.y = SLOT_LOCKS_Y;
    for (let i = 0; i < REELS_AMOUNT; i++) {
      const lockAnimation = new SpineAnimation({}, PIXI.Loader.shared.resources.locks.spineData!);
      this.locks.push(lockAnimation);
      lockAnimation.spine.y = SLOT_HEIGHT / 2;
      lockAnimation.spine.x = REEL_WIDTH * i + REEL_WIDTH / 2;
      locksContainer.addChild(lockAnimation.spine);
      lockAnimation.setAnimation('lock_symbol_static', true);
    }
    locksContainer.visible = !(
      setBrokenGame() &&
      (GameMode.ANGEL === getGameModeByBonusId(setCurrentBonus().bonusId) ||
        GameMode.ANGEL_AND_DEVIL === getGameModeByBonusId(setCurrentBonus().bonusId))
    );
    this.addChild(locksContainer);
    return locksContainer;
  }

  private startDestroyAngelLocks(): void {
    delayedAction(
      1000,
      () => {
        this.locksContainer.visible = false;
        this.locks.forEach((lock) => {
          lock.setAnimation('lock_symbol_static', true);
        });
      },
      () => {
        this.locks.forEach((lock) => lock.setAnimation('lock_symbol_animation', false));
      },
    );
  }

  private createTotalWinMessage(props: MessageBannerProps): void {
    this.addChild(new MessageBanner(props));
  }

  private createFreeSpinMessage(props: MessageBannerProps): void {
    this.addChild(new FreeSpinMessageBanner(props));
  }

  private removeFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    if (this.freeSpinsTitle) this.removeChild(this.freeSpinsTitle);
  }

  private createFreeSpinsTitle(props: FreeSpinsTitleProps): void {
    this.freeSpinsTitle = new FreeSpinsTitle(props);
    this.addChildAt(this.freeSpinsTitle, 3);
  }

  // private createFreeSpinsTitleFrame(props: FreeSpinsTitleProps): void {
  //   this.freeSpinsTitle = new FreeSpinsTitle(props);
  //   this.addChildAt(this.freeSpinsFrame, 3);
  // }

  // private createFreeSpinsTitleIcon(props: FreeSpinsTitleProps): void {
  //   this.freeSpinsTitle = new FreeSpinsTitle(props);
  //   this.addChildAt(this.freeSpinsIcon, 3);
  // }

  private onModeChange(settings: { mode: GameMode }): void {
    if (isRegularMode(settings.mode)) {
      this.multiplierTitle.visible = false;
      this.locksContainer.visible = true;
      this.multiplierLock.setAnimation('lock_multiplayer_static', true);
    }
    if (isFreeSpinMode(settings.mode)) {
      this.gameTitle.visible = false;
    } else {
      this.gameTitle.visible = true;
    }
  }

  private initDesktopReelsFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    frame.height = DESKTOP_REELS_FRAME_HEIGHT - 5;
    frame.width = DESKTOP_REELS_FRAME_WIDTH;
    frame.y = REELS_FRAME_POSITION_Y + 5;
    frame.x = REELS_FRAME_POSITION_X;
    return frame;
  }

  private initMobileReelsFrame(): PIXI.Sprite {
    const frame = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.frame));
    const ratio = frame.height / frame.width;
    frame.height = MOBILE_REELS_FRAME_HEIGHT * ratio - 88;
    frame.width = MOBILE_REELS_FRAME_WIDTH - 115;
    frame.y = REELS_FRAME_POSITION_Y + 5;
    frame.x = REELS_FRAME_POSITION_X;
    return frame;
  }

  private resize(width: number, height: number): void {
    this.scale.set(
      width / SLOTS_CONTAINER_WIDTH,
      (width * (SLOTS_CONTAINER_HEIGHT / SLOTS_CONTAINER_WIDTH)) / SLOTS_CONTAINER_HEIGHT,
    );
  }
}

export default GameView;
