import { useQuery, useReactiveVar } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import AudioHowl from '@phoenix7dev/play-music';
import { Toast } from '@phoenix7dev/shared-components';

import { EventTypes, ISettledBet } from '../../global.d';
import {
  setGameMode,
  setIsErrorMessage,
  setIsProcessToGame,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSoundOn,
  setIsSpinInProgress,
  setIsSuspended,
  setIsTimeoutErrorMessage,
  setSkipIntroScreen,
  setSlotConfig,
} from '../../gql/cache';
import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import Animator from '../../slotMachine/animations/animator';
import { GAME_CONTAINER_HEIGHT, GAME_CONTAINER_WIDTH, SlotMachineState, eventManager } from '../../slotMachine/config';
import { getPlayListOnBroken, getSpinResult, handleChangeRestriction, isFreeSpinMode, wrap } from '../../utils';
import AutoPlaySettingsMenu from '../AutoPlaySettings/AutoPlaySettingsMenu';
import BetSettingsMenu from '../BetSettings/BetSettingsMenu';
import BuyFeature from '../BuyFeature';
import Clock from '../Clock';
import EventListener from '../EventListener';
import IntroScreen from '../IntroScreen/introScreen';
import Menu from '../MenuButton';
import Spin from '../SpinButton';

import styles from './gameScreen.module.scss';

export interface IPixiViewParentNode extends Node, ParentNode {
  clientWidth: number;
  clientHeight: number;
}

export const application = new PIXI.Application({
  resolution: window.devicePixelRatio || 1,
  autoDensity: true,
  backgroundAlpha: 0,
  width: GAME_CONTAINER_WIDTH,
  height: GAME_CONTAINER_HEIGHT,
});
((globalThis as unknown) as { __PIXI_APP__: unknown }).__PIXI_APP__ = application;
export const animator = new Animator(application);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.PIXI = PIXI;

const GameScreen: React.FC = () => {
  const { t } = useTranslation();
  const { data: clientData } = useQuery<IConfig>(configGql);
  const { isMiniPayTable } = clientData!;
  const { isSoundOn, isShowSuspendedToast } = clientData!;
  const isLoading = useReactiveVar(setIsSoundLoading);
  const skipIntro = useReactiveVar(setSkipIntroScreen);
  const processToGame = useReactiveVar(setIsProcessToGame);
  const pixiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    AudioHowl.mute(isSoundOn);
  }, [isSoundOn]);

  useEffect(() => {
    eventManager.emit(EventTypes.DISABLE_PAY_TABLE, isMiniPayTable);
    if (!isMiniPayTable) {
      eventManager.emit(EventTypes.DISABLE_ALL_MINI_PAY_TABLES);
    }
  }, [isMiniPayTable]);
  useEffect(() => {
    pixiContainerRef.current?.appendChild(application.view);

    const handleResize = (): void => {
      const parent = pixiContainerRef.current!;
      const width = parent.clientWidth;
      const height = parent.clientHeight;
      eventManager.emit(EventTypes.RESIZE, width, height);
    };

    const resize = (): void => {
      const { userAgent } = navigator;
      // resize fix for Chrome browsers on Ios devices
      if (userAgent.includes('CriOS') && (userAgent.includes('iPhone') || userAgent.includes('iPad'))) {
        setTimeout(() => {
          handleResize();
        }, 50);
      } else {
        handleResize();
      }
    };
    if (!setSkipIntroScreen()) {
      IntroScreen.initIntroScreen(application);
      eventManager.addListener(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
        SlotMachine.initSlotMachine(
          application,
          setSlotConfig(),
          wrap(setIsSpinInProgress, false),
          wrap(setIsSlotBusy, false),
        );
        // overriding it here coz slotmachine index.ts is a critical file, and cannot be modified.
        // SlotMachine.getInstance().setResult = (result: ISettledBet) => {
        // const spinResult = getSpinResult({
        //   reelPositions: result.bet.result.reelPositions,
        //   reelSet: result.bet.reelSet,
        //   icons: setSlotConfig().icons,
        // });
        // const newResult = {
        //   ...result,
        //   bet: {
        //     ...result.bet,
        //     result: {
        //       ...result.bet.result,
        //       spinResult,
        //     },
        //     data: {
        //       ...result.bet.data,
        //       features: {
        //         ...result.bet.data.features,
        //         cascade: result.bet.data.features.cascade.filter((cascade) => cascade.winPositions.length > 0),
        //       },
        //     },
        //   },
        // };
        // SlotMachine.getInstance().nextResult = newResult;
        // if (!isFreeSpinMode(setGameMode())) {
        //   eventManager.emit(EventTypes.UPDATE_USER_BALANCE, SlotMachine.getInstance().nextResult?.balance.placed);
        // }
        // };
        // SlotMachine.getInstance().throwTimeoutError = () => {
        //   if (!setIsRevokeThrowingError()) {
        //     setIsTimeoutErrorMessage(true);
        //   }
        //   eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
        //   eventManager.emit(EventTypes.THROW_ERROR);
        // };
        SlotMachine.getInstance().onSpinStop = () => {
          wrap(setIsSpinInProgress, false)();
          if (setIsErrorMessage()) {
            SlotMachine.getInstance().state = SlotMachineState.IDLE;
            eventManager.emit(EventTypes.DISABLE_PAY_TABLE, !isFreeSpinMode(setGameMode()));
            setIsErrorMessage(false);
            eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
            eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.IDLE);
          } else {
            SlotMachine.getInstance().state = SlotMachineState.WINNING;
            eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
            eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.WINNING);
          }
        };
        handleResize();
      });
    } else {
      setIsProcessToGame(true);
      SlotMachine.initSlotMachine(
        application,
        setSlotConfig(),
        wrap(setIsSpinInProgress, false),
        wrap(setIsSlotBusy, false),
      );
      // overriding it here coz slotmachine index.ts is a critical file, and cannot be modified.
      // SlotMachine.getInstance().setResult = (result: ISettledBet) => {
      //   const spinResult = getSpinResult({
      //     reelPositions: result.bet.result.reelPositions,
      //     reelSet: result.bet.reelSet,
      //     icons: setSlotConfig().icons,
      //   });
      //   const newResult = {
      //     ...result,
      //     bet: {
      //       ...result.bet,
      //       result: {
      //         ...result.bet.result,
      //         spinResult,
      //       },
      //       data: {
      //         ...result.bet.data,
      //         features: {
      //           ...result.bet.data.features,
      //           cascade: result.bet.data.features.cascade.filter((cascade) => cascade.winPositions.length > 0),
      //         },
      //       },
      //     },
      //   };
      //   SlotMachine.getInstance().nextResult = newResult;
      //   if (!isFreeSpinMode(setGameMode())) {
      //     eventManager.emit(EventTypes.UPDATE_USER_BALANCE, SlotMachine.getInstance().nextResult?.balance.placed);
      //   }
      // };
      // SlotMachine.getInstance().throwTimeoutError = () => {
      //   if (!setIsRevokeThrowingError()) {
      //     setIsTimeoutErrorMessage(true);
      //   }
      //   eventManager.emit(EventTypes.BREAK_SPIN_ANIMATION);
      //   eventManager.emit(EventTypes.THROW_ERROR);
      // };
      SlotMachine.getInstance().onSpinStop = () => {
        wrap(setIsSpinInProgress, false)();
        if (setIsErrorMessage()) {
          SlotMachine.getInstance().state = SlotMachineState.IDLE;
          eventManager.emit(EventTypes.DISABLE_PAY_TABLE, !isFreeSpinMode(setGameMode()));
          setIsErrorMessage(false);
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
          eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.IDLE);
        } else {
          SlotMachine.getInstance().state = SlotMachineState.WINNING;
          eventManager.emit(EventTypes.DISABLE_PAY_TABLE, false);
          eventManager.emit(EventTypes.SLOT_MACHINE_STATE_CHANGE, SlotMachineState.WINNING);
        }
      };
      handleResize();
    }

    handleResize();
    window.addEventListener(EventTypes.RESIZE, resize);
    return () => window.removeEventListener(EventTypes.RESIZE, resize);
  }, []);

  return (
    <>
      <div className={styles.canvas} ref={pixiContainerRef} />
      {processToGame && (
        <>
          <EventListener />
          <Clock />
          <BuyFeature />
          <AutoPlaySettingsMenu />
          <Menu />
          <BetSettingsMenu />
          <Spin />
          {AudioHowl.isRestricted &&
            !AudioHowl.restrictionChangedOnIntroScreen &&
            !AudioHowl.hideRestrictionModal &&
            !isShowSuspendedToast &&
            setIsSoundOn() && (
              <Toast
                title={t('soundWarningTitle')}
                text={t('soundWarningText')}
                btnText={t('soundWarningBtn')}
                isLoading={isLoading}
                handleClick={() => {
                  if (!AudioHowl.isInitialized) {
                    eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
                  }
                  AudioHowl.changeRestriction(
                    false,
                    [],
                    () => setIsSoundLoading(true),
                    () => {
                      setIsShowSoundToast(false);
                      handleChangeRestriction();
                    },
                  );
                }}
              />
            )}
          {isShowSuspendedToast && (
            <Toast
              title={t('suspendedWarningTitle')}
              text={t('suspendedWarningText')}
              btnText={t('suspendedWarningBtn')}
              handleClick={() => {
                setIsSuspended(false);
                AudioHowl.unSuspend();
                if (!AudioHowl.isInitialized) {
                  eventManager.emit(EventTypes.ENABLE_SOUND_LOADER);
                }
                AudioHowl.changeRestriction(
                  false,
                  [],
                  () => setIsSoundLoading(true),
                  () => {
                    setIsShowSoundToast(false);
                    handleChangeRestriction();
                  },
                );
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default GameScreen;
