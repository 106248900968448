import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter();

window.eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  SPIN,
  JINGLE,
  WINNING,
  RETRIGGER,
  ANGEL_FS_START,
  DEVIL_FS_START,
  ANGEL_AND_DEVIL_FS_START,
}
// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = true;
export const APPLICATION_FPS = 60;

// RESET ANIMATION
export const RESET_ANIMATION_BASE_DURATION = 300;
export const RESET_ANIMATION_TURBO_DURATION = 200;

// WIN SETTINGS
export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.6;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.001;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_COUNT_UP_SCALE = 1.9;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.03;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_COUNT_UP_SCALE = 2;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_COUNT_UP_SCALE = 2.1;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_COUNT_UP_SCALE = 2.2;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 6;
export const SLOTS_PER_REEL_AMOUNT = 6;
export const REEL_WIDTH = 180;
export const SLOT_WIDTH = 180;
export const SLOT_HEIGHT = 150;
export const SCATTER_SLOT_WIDTH = 210;
export const SCATTER_SLOT_HEIGHT = 175;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 140;
export const GAME_CONTAINER_WIDTH = SLOTS_CONTAINER_WIDTH;

// COUNT UP
export const COUNT_UP_MESSAGE_X = SLOTS_CONTAINER_WIDTH / 2;
export const COUNT_UP_MESSAGE_Y = 1110;
export const COUNT_UP_MESSAGE_WIDTH = 180;
export const COUNT_UP_MESSAGE_HEIGHT = 100;
export const COUNT_UP_WIN_AMOUNT_TEXT_DURATION = 500;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [
  SlotId.SC1,
  SlotId.SC2,
  SlotId.SC3,
  SlotId.SC4,
  SlotId.SC5,
  SlotId.SC6,
  SlotId.SC7,
  SlotId.SC8,
];
export const ANTICIPATION_SYMBOLS_AMOUNT = [2, 2, 2, 2, 2, 2, 2, 2];

export const HIGH_PAYING_SYMBOLS = [SlotId.A, SlotId.B, SlotId.C, SlotId.D, SlotId.E];
// GAME TITLE
export const TITLE_LOGO_X = 380;
export const MOBILE_TITLE_LOGO_X = 387;
export const TITLE_LOGO_WIDTH = 715;
export const MOBILE_TITLE_LOGO_WIDTH = 700;
export const TITLE_LOGO_HEIGHT = 120;

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 36;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 36;

// REELS SETTINGS
export const BASE_DISAPPEARING_DURATION = 300;
export const TURBO_DISAPPEARING_DURATION = 200;
export const BASE_APPEARING_DURATION = 500;
export const TURBO_APPEARING_DURATION = 300;
export enum ReelState {
  IDLE,
  DISAPPEARING,
  WAITING,
  APPEARING,
}
// NUMBER FORMAT
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

// SPIN ANIMATION SETTINGS
export const DELAY_BETWEEN_REELS = 40;
export const DELAY_BETWEEN_SLOTS = 20;
export const FORCE_STOP_CASCADE_ANIMATION_DURATION = 200;
export const FORCE_STOP_CASCADE_PER_EACH_DURATION = 0;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = REEL_WIDTH + SLOT_WIDTH / 2 - 10;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT * 0.65;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;

// FREE SPINS
export const FREE_SPINS_FADE_DURATION = 2000;
export const FREE_SPINS_TIME_OUT_BANNER = 1000;
export const CHARACTERS_FEATURE_WIN_DURATION = 3633;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// BUY FEATURE BTN
export const FEATURE_BTN_CONTAINER_POSITION_Y = 200;
export const FEATURE_BTN_CONTAINER_POSITION_X = -280;
export const FEATURE_BTN_WIDTH = 200;
export const FEATURE_BTN_HEIGHT = 200;

// BUY FEATURE POPUP
export const FEATURE_POPUP_TEXT_ALPHA = 0.25;
export const FEATURE_POPUP_WIDTH = 1700;
export const MOBILE_FEATURE_POPUP_WIDTH = 1150;
export const FEATURE_POPUP_HEIGHT = 1150;
export const MOBILE_FEATURE_POPUP_HEIGHT = 1150;
export const FEATURE_POPUP_POSITION_X = -310;
export const MOBILE_FEATURE_POPUP_POSITION_X = -35;
export const FEATURE_POPUP_POSITION_Y = -86;
export const MOBILE_FEATURE_POPUP_POSITION_Y = -115;
export const FEATURE_POPUP_TITLE_POSITION_Y = 35;
export const FEATURE_POPUP_TITLE_POSITION_X = 585;
export const MOBILE_FEATURE_POPUP_TITLE_POSITION_X = 555;

// BUY FEATURE ANGEL
export const FEATURE_ANGEL_BTN_Y = 300;
export const FEATURE_ANGEL_BTN_X = 580;
export const MOBILE_FEATURE_ANGEL_BTN_X = 545;
export const MOBILE_FEATURE_FORM_ANGEL_BTN_X = 545;

// BUY FEATURE DEVIL
export const FEATURE_DEVIL_BTN_Y = FEATURE_ANGEL_BTN_Y;
export const FEATURE_DEVIL_BTN_X = 80;
export const MOBILE_FEATURE_DEVIL_BTN_X = 160;
export const MOBILE_FEATURE_FORM_DEVIL_BTN_X = 165;

// BUY FEATURE ANGEL AND DEVIL
export const FEATURE_ANGEL_AND_DEVIL_BTN_Y = FEATURE_ANGEL_BTN_Y;
export const FEATURE_ANGEL_AND_DEVIL_BTN_X = 1000;
export const MOBILE_FEATURE_ANGEL_AND_DEVIL_BTN_X = 920;

// BUY FEATURE CANCEL
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 900;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = 350;
export const MOBILE_FEATURE_POPUP_CANCEL_BTN_POSITION_X = 300;

// BUY FEATURE OK
export const FEATURE_POPUP_OK_BTN_POSITION_Y = 900;
export const FEATURE_POPUP_OK_BTN_POSITION_X = 830;

// BUY FEATURE BACKGROUND
export const FEATURE_BUTTON_BACKGROUND_Y = FEATURE_ANGEL_BTN_Y + 100;
export const FEATURE_BUTTON_BACKGROUND_X = FEATURE_ANGEL_BTN_X;
export const MOBILE_FEATURE_BUTTON_BACKGROUND_X = 545;

// BUY FEATURE ANGEL TOTAL COST & LABEL
export const FEATURE_POPUP_ANGEL_LABEL_TEXT_X = FEATURE_ANGEL_BTN_X;
export const MOBILE_FEATURE_POPUP_ANGEL_LABEL_TEXT_X = 545;
export const FEATURE_POPUP_ANGEL_LABEL_TEXT_Y = 440;
export const FEATURE_POPUP_ANGEL_TOTAL_CONST_TEXT_AMOUNT_X = FEATURE_ANGEL_BTN_X;
export const FEATURE_POPUP_ANGEL_TOTAL_CONST_TEXT_AMOUNT_Y = 520;

// BUY FEATURE DEVIL TOTAL COST & LABEL
export const FEATURE_POPUP_DEVIL_LABEL_TEXT_X = FEATURE_DEVIL_BTN_X;
export const MOBILE_FEATURE_POPUP_DEVIL_LABEL_TEXT_X = 170;
export const FEATURE_POPUP_DEVIL_LABEL_TEXT_Y = 440;
export const FEATURE_POPUP_DEVIL_TOTAL_CONST_TEXT_AMOUNT_X = FEATURE_DEVIL_BTN_X;
export const FEATURE_POPUP_DEVIL_TOTAL_CONST_TEXT_AMOUNT_Y = 520;

// BUY FEATURE ANGEL AND DEVIL TOTAL COST & LABEL
export const FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_X = FEATURE_ANGEL_AND_DEVIL_BTN_X;
export const MOBILE_FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_X = 920;
export const FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_Y = 440;
export const FEATURE_POPUP_ANGEL_AND_DEVIL_TOTAL_CONST_TEXT_AMOUNT_X = FEATURE_ANGEL_AND_DEVIL_BTN_X;
export const FEATURE_POPUP_ANGEL_AND_DEVIL_TOTAL_CONST_TEXT_AMOUNT_Y = 520;

// BUY FEATURE AMOUNT
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_X = 355;
export const MOBILE_FEATURE_POPUP_AMOUNT_TEXT_POSITION_X = 305;
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y = 722;

// BUY FEATURE MINUS
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = 750;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X = 660;

// BUY FEATURE PLUS
export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = 750;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X = 1000;

// BUY FEATURE INPUT
export const FEATURE_POPUP_INPUT_POSITION_Y = 750;
export const FEATURE_POPUP_INPUT_POSITION_X = FEATURE_POPUP_OK_BTN_POSITION_X;

// BUY FEATURE VALUE
export const FEATURE_POPUP_BET_VALUE_POSITION_Y = 722;
export const FEATURE_POPUP_BET_VALUE_POSITION_X = FEATURE_POPUP_OK_BTN_POSITION_X;

// BUY FEATURE CONFIRM
export const FEATURE_POPUP_CONFIRM_WIDTH = 1300;
export const MOBILE_FEATURE_POPUP_CONFIRM_WIDTH = 1100;
export const FEATURE_POPUP_CONFIRM_HEIGHT = 1100;
export const MOBILE_FEATURE_POPUP_CONFIRM_HEIGHT = 1100;
export const FEATURE_POPUP_CONFIRM_POSITION_Y = -90;
export const MOBILE_FEATURE_POPUP_CONFIRM_POSITION_Y = -115;
export const FEATURE_POPUP_CONFIRM_POSITION_X = -50;
export const MOBILE_FEATURE_POPUP_CONFIRM_POSITION_X = -10;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = 75;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_X = 585;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = 180;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = 500;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y = 790;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X = 410;
export const MOBILE_FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X = 310;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y = 790;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X = 785;
export const FEATURE_CONFIRM_COST_Y = 450;
export const MOBILE_TOTAL_COST_VALUE = 555;
export const FEATURE_CONFIRM_COST_X = 600;
export const FEATURE_TITLE_X = 600;
export const FEATURE_TITLE_Y = 300;
export const FEATURE_TOTAL_COST_X = 605;
export const FEATURE_TOTAL_COST_Y = 600;

// FRAME
export const REELS_FRAME_POSITION_X = -50;
export const REELS_FRAME_POSITION_Y = -18;
export const DESKTOP_REELS_FRAME_WIDTH = 1180;
export const DESKTOP_REELS_FRAME_HEIGHT = 1100;
export const MOBILE_REELS_FRAME_WIDTH = GAME_CONTAINER_WIDTH + 215;
export const MOBILE_REELS_FRAME_HEIGHT = GAME_CONTAINER_WIDTH + 180;

// MULTIPLIER

export const MULTIPLIER_MOVE_TIME = 700;
export const MULTIPLIER_TITLE_X = 180;
export const MULTIPLIER_TITLE_Y = 60;

// LOCKS
export const MULTIPLIER_LOCK_HEIGHT = 100;
export const MULTIPLIER_LOCK_X = 180;
export const MULTIPLIER_LOCK_Y = 60;
export const SLOT_LOCKS_Y = 130;
