import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import { GameMode } from '../../global.d';
import { setGameMode } from '../../gql/cache';
import { ResourceTypes } from '../../resources.d';

import {
  BANNER_HEIGHT,
  BANNER_WIDTH,
  LEFT_CHARACTER_X,
  LEFT_CHARACTER_Y,
  MOBILE_BANNER_HEIGHT,
  MOBILE_BANNER_POSITION_X,
  MOBILE_BANNER_WIDTH,
  MOBILE_LEFT_CHARACTER_X,
  MOBILE_RIGHT_CHARACTER_X,
  RIGHT_CHARACTER_X,
  RIGHT_CHARACTER_Y,
} from './config';
import MessageBanner from './messageBanner';

export class FreeSpinMessageBanner extends MessageBanner {
  private angelTexture: PIXI.Texture | undefined;

  private devilTexture: PIXI.Texture | undefined;

  public leftCharacter: PIXI.Sprite | undefined;

  public rightCharacter: PIXI.Sprite | undefined;

  protected init(): void {
    this.angelTexture = PIXI.Texture.from(ResourceTypes.freeSpinBannerAngel);
    this.devilTexture = PIXI.Texture.from(ResourceTypes.freeSpinBannerDevil);
    this.leftCharacter = this.initLeftCharacter();
    this.rightCharacter = this.initRightCharacter();
    super.init();

    this.addChild(this.leftCharacter);
    this.addChild(this.rightCharacter);
  }

  private initLeftCharacter(): PIXI.Sprite {
    const texture = setGameMode() === GameMode.ANGEL ? this.angelTexture : this.devilTexture;
    const sprite = new PIXI.Sprite(texture);
    sprite.x = isMobile ? MOBILE_LEFT_CHARACTER_X : LEFT_CHARACTER_X;
    sprite.y = LEFT_CHARACTER_Y;
    if (isMobile) {
      sprite.scale.set(1.2, 1.2);
    }
    sprite.anchor.set(0.5, 0.5);
    return sprite;
  }

  private initRightCharacter(): PIXI.Sprite {
    const texture = setGameMode() === GameMode.DEVIL ? this.devilTexture : this.angelTexture;
    const sprite = new PIXI.Sprite(texture);
    if (isMobile) {
      sprite.scale.set(-1.2, 1.2);
    } else {
      sprite.scale.set(-1, 1);
    }
    sprite.x = isMobile ? MOBILE_RIGHT_CHARACTER_X : RIGHT_CHARACTER_X;
    sprite.y = RIGHT_CHARACTER_Y;
    sprite.anchor.set(0.5, 0.5);

    return sprite;
  }

  private getBannerTexture(): PIXI.Texture {
    if (setGameMode() === GameMode.ANGEL) {
      return PIXI.Texture.from(ResourceTypes.confirmAngelBg);
    }
    if (setGameMode() === GameMode.DEVIL) {
      return PIXI.Texture.from(ResourceTypes.confirmDevilBg);
    }
    return PIXI.Texture.from(ResourceTypes.confirmAngelAndDevilBg);
  }

  protected initBanner(): PIXI.Sprite {
    const banner = new PIXI.Sprite(this.getBannerTexture());
    banner.width = isMobile ? MOBILE_BANNER_WIDTH : BANNER_WIDTH;
    banner.height = isMobile ? MOBILE_BANNER_HEIGHT : BANNER_HEIGHT;
    banner.x = isMobile ? MOBILE_BANNER_POSITION_X : 0;
    return banner;
  }
}
