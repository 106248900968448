import i18n from 'i18next';
import * as _ from 'lodash';
import { isMobile } from 'mobile-device-detect';
import * as PIXI from 'pixi.js';

import AudioHowl from '@phoenix7dev/play-music';

import { ISongs } from '../../config';
import { EventTypes, FeatureTypes, IBonus } from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setCoinAmount,
  setCurrency,
  setIsBuyFeaturePopupOpened,
  setSlotConfig,
} from '../../gql/cache';
import { IBetSettings } from '../../gql/d';
import { getBetsSetting } from '../../gql/fromFragment';
import { ResourceTypes } from '../../resources.d';
import {
  FeatureState,
  formatNumber,
  getBonusIdByFeature,
  isMobileDevice,
  normalizeCoins,
  showCurrency,
} from '../../utils';
import { TextField } from '../components/TextField';
import { FeatureButton } from '../components/featureButton';
import { SpriteButton, SpriteButtonState } from '../components/spriteButton';
import {
  FEATURE_ANGEL_AND_DEVIL_BTN_X,
  FEATURE_ANGEL_AND_DEVIL_BTN_Y,
  FEATURE_ANGEL_BTN_X,
  FEATURE_ANGEL_BTN_Y,
  FEATURE_BUTTON_BACKGROUND_X,
  FEATURE_BUTTON_BACKGROUND_Y,
  FEATURE_DEVIL_BTN_X,
  FEATURE_DEVIL_BTN_Y,
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_X,
  FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y,
  FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_X,
  FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_Y,
  FEATURE_POPUP_ANGEL_AND_DEVIL_TOTAL_CONST_TEXT_AMOUNT_X,
  FEATURE_POPUP_ANGEL_AND_DEVIL_TOTAL_CONST_TEXT_AMOUNT_Y,
  FEATURE_POPUP_ANGEL_LABEL_TEXT_X,
  FEATURE_POPUP_ANGEL_LABEL_TEXT_Y,
  FEATURE_POPUP_ANGEL_TOTAL_CONST_TEXT_AMOUNT_X,
  FEATURE_POPUP_ANGEL_TOTAL_CONST_TEXT_AMOUNT_Y,
  FEATURE_POPUP_BET_VALUE_POSITION_X,
  FEATURE_POPUP_BET_VALUE_POSITION_Y,
  FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  FEATURE_POPUP_CANCEL_BTN_POSITION_Y,
  FEATURE_POPUP_DEVIL_LABEL_TEXT_X,
  FEATURE_POPUP_DEVIL_LABEL_TEXT_Y,
  FEATURE_POPUP_DEVIL_TOTAL_CONST_TEXT_AMOUNT_X,
  FEATURE_POPUP_DEVIL_TOTAL_CONST_TEXT_AMOUNT_Y,
  FEATURE_POPUP_HEIGHT,
  FEATURE_POPUP_INPUT_POSITION_X,
  FEATURE_POPUP_INPUT_POSITION_Y,
  FEATURE_POPUP_MINUS_BTN_POSITION_X,
  FEATURE_POPUP_MINUS_BTN_POSITION_Y,
  FEATURE_POPUP_OK_BTN_POSITION_X,
  FEATURE_POPUP_OK_BTN_POSITION_Y,
  FEATURE_POPUP_PLUS_BTN_POSITION_X,
  FEATURE_POPUP_PLUS_BTN_POSITION_Y,
  FEATURE_POPUP_POSITION_X,
  FEATURE_POPUP_POSITION_Y,
  FEATURE_POPUP_TITLE_POSITION_X,
  FEATURE_POPUP_TITLE_POSITION_Y,
  FEATURE_POPUP_WIDTH,
  MOBILE_FEATURE_ANGEL_AND_DEVIL_BTN_X,
  MOBILE_FEATURE_ANGEL_BTN_X,
  MOBILE_FEATURE_BUTTON_BACKGROUND_X,
  MOBILE_FEATURE_DEVIL_BTN_X,
  MOBILE_FEATURE_FORM_ANGEL_BTN_X,
  MOBILE_FEATURE_FORM_DEVIL_BTN_X,
  MOBILE_FEATURE_POPUP_AMOUNT_TEXT_POSITION_X,
  MOBILE_FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_X,
  MOBILE_FEATURE_POPUP_ANGEL_LABEL_TEXT_X,
  MOBILE_FEATURE_POPUP_CANCEL_BTN_POSITION_X,
  MOBILE_FEATURE_POPUP_DEVIL_LABEL_TEXT_X,
  MOBILE_FEATURE_POPUP_HEIGHT,
  MOBILE_FEATURE_POPUP_POSITION_X,
  MOBILE_FEATURE_POPUP_POSITION_Y,
  MOBILE_FEATURE_POPUP_TITLE_POSITION_X,
  MOBILE_FEATURE_POPUP_WIDTH,
  eventManager,
} from '../config';

import {
  amountTextStyle,
  betValueStyle,
  buyFeatureActiveTextStyle,
  buyFeatureDisableTextStyle,
  buyFeatureTitleStyle,
  totalCostActiveTextStyle,
  totalCostDisableTextStyle,
} from './textStyles';

class BuyFeaturePopup extends PIXI.Container {
  private popupBg: PIXI.Sprite;

  private okBtn: SpriteButton;

  private cancelBtn: SpriteButton;

  private featureBtnBg: PIXI.Sprite;

  private angelFeatureButton: FeatureButton;

  private devilFeatureButton: FeatureButton;

  private angelAndDevilFeatureButton: FeatureButton;

  private titleText: TextField;

  private angelLabelText: TextField;

  private angelTotalCostTextAmount: PIXI.Text;

  private devilLabelText: TextField;

  private devilTotalCostTextAmount: PIXI.Text;

  private angelAndDevilLabelText: TextField;

  private angelAndDevilTotalCostTextAmount: PIXI.Text;

  private amountText: PIXI.Text;

  private minusBtn: SpriteButton;

  private plusBtn: SpriteButton;

  private input: PIXI.Sprite;

  private betSettings: IBetSettings;

  private betAmount: number;

  private coinMultiplier: number;

  private currency = 'FUN';

  private betValue: PIXI.Text;

  private isNoFunds: boolean;

  private balance: number;

  private featureState: FeatureState = FeatureState.ANGEL;

  constructor() {
    super();
    this.betSettings = getBetsSetting();
    this.x = 0;
    this.y = 75;
    this.visible = false;
    this.coinMultiplier = setSlotConfig().lineSets[0].coinAmountMultiplier;
    this.balance = 0;
    this.isNoFunds = false;
    this.interactive = true;
    this.currency = setCurrency();
    this.betAmount = this.getBetAmount(setBetAmount());
    this.popupBg = this.initPopupBg();
    this.titleText = this.initTitle();
    this.featureBtnBg = this.initFeatureBtnBg();
    this.angelTotalCostTextAmount = this.initAngelTotalCostTextAmount();
    this.angelLabelText = this.initAngelLabelText();
    this.devilTotalCostTextAmount = this.initDevilTotalCostTextAmount();
    this.devilLabelText = this.initDevilLabelText();
    this.angelAndDevilTotalCostTextAmount = this.initAngelAndDevilTotalCostTextAmount();
    this.angelAndDevilLabelText = this.initAngelAndDevilLabelText();
    this.amountText = this.initAmountText();
    this.minusBtn = this.initMinusBtn();
    this.plusBtn = this.initPlusBtn();
    this.input = this.initInput();
    this.betValue = this.initBetValue();
    this.cancelBtn = this.initCancelBtn();
    this.angelFeatureButton = this.initAngelButton();
    this.devilFeatureButton = this.initDevilButton();
    this.angelAndDevilFeatureButton = this.initAngelAndDevilButton();
    this.okBtn = this.initOkBtn();
    this.init();
    eventManager.on(EventTypes.OPEN_BUY_FEATURE_POPUP, () => {
      this.closeAllAnimationsInSlot();
      this.visible = true;
      setIsBuyFeaturePopupOpened(true);
    });
    eventManager.on(EventTypes.UPDATE_BET, () => {
      this.betAmount = this.getBetAmount(setBetAmount());
      this.updateBets();
      this.handleDisable();
    });
    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, () => {
      this.visible = false;
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
      setIsBuyFeaturePopupOpened(false);
    });
    eventManager.on(EventTypes.CLOSE_BUY_FEATURE_CONFIRM_POPUP, (closeBuyFeature: boolean) => {
      if (!closeBuyFeature) {
        this.visible = true;
      } else {
        this.onCancel();
      }
    });
    eventManager.on(EventTypes.UPDATE_USER_BALANCE, (balance: { currency: string; amount: number }) => {
      this.balance = balance.amount / 100;
      this.handleDisable();
    });
  }

  private init(): void {
    this.addChild(this.popupBg);
    this.addChild(this.featureBtnBg);
    this.addChild(this.titleText.getText());
    this.addChild(this.angelTotalCostTextAmount);
    this.addChild(this.devilTotalCostTextAmount);
    this.addChild(this.angelAndDevilTotalCostTextAmount);
    this.addChild(this.angelLabelText.getText());
    this.addChild(this.devilLabelText.getText());
    this.addChild(this.angelAndDevilLabelText.getText());
    this.addChild(this.amountText);
    this.addChild(this.minusBtn);
    this.addChild(this.plusBtn);
    this.addChild(this.input);
    this.addChild(this.betValue);
    this.addChild(this.okBtn);
    this.addChild(this.cancelBtn);
    this.addChild(this.angelFeatureButton);
    this.addChild(this.devilFeatureButton);
    this.addChild(this.angelAndDevilFeatureButton);
  }

  private initPopupBg(): PIXI.Sprite {
    const popupBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeaturePopup));
    popupBg.width = isMobile ? MOBILE_FEATURE_POPUP_WIDTH : FEATURE_POPUP_WIDTH;
    popupBg.height = isMobile ? MOBILE_FEATURE_POPUP_HEIGHT : FEATURE_POPUP_HEIGHT;
    popupBg.x = isMobile ? MOBILE_FEATURE_POPUP_POSITION_X : FEATURE_POPUP_POSITION_X;
    popupBg.y = isMobile ? MOBILE_FEATURE_POPUP_POSITION_Y : FEATURE_POPUP_POSITION_Y;

    return popupBg;
  }

  private initTitle(): TextField {
    const title = new TextField(i18n.t('buyFeatureTitle'), 800, 100, buyFeatureTitleStyle as Partial<PIXI.ITextStyle>);
    title.text.y = FEATURE_POPUP_TITLE_POSITION_Y;
    title.text.x = isMobile ? MOBILE_FEATURE_POPUP_TITLE_POSITION_X : FEATURE_POPUP_TITLE_POSITION_X;
    title.text.anchor.set(0.5, 0.5);

    return title;
  }

  private initFeatureBtnBg(): PIXI.Sprite {
    const featureBtnBg = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.featureButtonBg));
    featureBtnBg.anchor.set(0.5, 0.5);
    featureBtnBg.y = FEATURE_BUTTON_BACKGROUND_Y;
    featureBtnBg.x = isMobile ? MOBILE_FEATURE_BUTTON_BACKGROUND_X : FEATURE_BUTTON_BACKGROUND_X;
    if (isMobile) {
      featureBtnBg.scale.set(0.8, 0.8);
    }

    return featureBtnBg;
  }

  private initAngelLabelText(): TextField {
    const angelLabel = new TextField(
      i18n.t('angelFeatureTitle'),
      250,
      80,
      buyFeatureDisableTextStyle as Partial<PIXI.ITextStyle>,
    );
    angelLabel.text.y = FEATURE_POPUP_ANGEL_LABEL_TEXT_Y;
    angelLabel.text.x = isMobile ? MOBILE_FEATURE_POPUP_ANGEL_LABEL_TEXT_X : FEATURE_POPUP_ANGEL_LABEL_TEXT_X;
    angelLabel.text.anchor.set(0.5, 0);

    angelLabel.text.style = buyFeatureActiveTextStyle;

    return angelLabel;
  }

  private initDevilLabelText(): TextField {
    const devilLabel = new TextField(
      i18n.t('devilFeatureTitle'),
      250,
      80,
      buyFeatureDisableTextStyle as Partial<PIXI.ITextStyle>,
    );
    devilLabel.text.y = FEATURE_POPUP_DEVIL_LABEL_TEXT_Y;
    devilLabel.text.x = isMobile ? MOBILE_FEATURE_POPUP_DEVIL_LABEL_TEXT_X : FEATURE_POPUP_DEVIL_LABEL_TEXT_X;
    devilLabel.text.anchor.set(0.5, 0);

    return devilLabel;
  }

  private initAngelAndDevilLabelText(): TextField {
    const angelAndDevilLabel = new TextField(
      i18n.t('angelAndDevilFeatureTitle'),
      250,
      80,
      buyFeatureDisableTextStyle as Partial<PIXI.ITextStyle>,
    );
    angelAndDevilLabel.text.y = FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_Y;
    angelAndDevilLabel.text.x = isMobile
      ? MOBILE_FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_X
      : FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_X;
    angelAndDevilLabel.text.anchor.set(0.5, 0);
    return angelAndDevilLabel;
  }

  private initAngelTotalCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getTotalCost(FeatureState.ANGEL), totalCostActiveTextStyle);
    totalCostTextAmount.y = FEATURE_POPUP_ANGEL_TOTAL_CONST_TEXT_AMOUNT_Y;
    totalCostTextAmount.x = isMobile
      ? MOBILE_FEATURE_POPUP_ANGEL_LABEL_TEXT_X
      : FEATURE_POPUP_ANGEL_TOTAL_CONST_TEXT_AMOUNT_X;
    totalCostTextAmount.anchor.set(0.5, 0);

    return totalCostTextAmount;
  }

  private initDevilTotalCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(this.getTotalCost(FeatureState.DEVIL), totalCostDisableTextStyle);
    totalCostTextAmount.y = FEATURE_POPUP_DEVIL_TOTAL_CONST_TEXT_AMOUNT_Y;
    totalCostTextAmount.x = isMobile
      ? MOBILE_FEATURE_POPUP_DEVIL_LABEL_TEXT_X
      : FEATURE_POPUP_DEVIL_TOTAL_CONST_TEXT_AMOUNT_X;
    totalCostTextAmount.anchor.set(0.5, 0);

    return totalCostTextAmount;
  }

  private initAngelAndDevilTotalCostTextAmount(): PIXI.Text {
    const totalCostTextAmount = new PIXI.Text(
      this.getTotalCost(FeatureState.ANGEL_AND_DEVIL),
      totalCostDisableTextStyle,
    );
    totalCostTextAmount.y = FEATURE_POPUP_ANGEL_AND_DEVIL_TOTAL_CONST_TEXT_AMOUNT_Y;
    totalCostTextAmount.x = isMobile
      ? MOBILE_FEATURE_POPUP_ANGEL_AND_DEVIL_LABEL_TEXT_X
      : FEATURE_POPUP_ANGEL_AND_DEVIL_TOTAL_CONST_TEXT_AMOUNT_X;
    totalCostTextAmount.anchor.set(0.5, 0);

    return totalCostTextAmount;
  }

  private initAmountText(): PIXI.Text {
    const amountText = new PIXI.Text(i18n.t('buyFeatureBetPerGame'), amountTextStyle as Partial<PIXI.ITextStyle>);
    amountText.y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y;
    amountText.x = isMobile ? MOBILE_FEATURE_POPUP_AMOUNT_TEXT_POSITION_X : FEATURE_POPUP_AMOUNT_TEXT_POSITION_X;
    amountText.anchor.set(0.5, 0);

    return amountText;
  }

  private initMinusBtn(): SpriteButton {
    const minusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureMinusBtnDisable),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleMinus.bind(this),
      onTouchStart: this.handleMinus.bind(this),
    });
    minusBtn.y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
    minusBtn.x = FEATURE_POPUP_MINUS_BTN_POSITION_X;
    return minusBtn;
  }

  private initPlusBtn(): SpriteButton {
    const plusBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeaturePlusBtnDisable),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handlePlus.bind(this),
      onTouchStart: this.handlePlus.bind(this),
    });
    plusBtn.y = FEATURE_POPUP_PLUS_BTN_POSITION_Y;
    plusBtn.x = FEATURE_POPUP_PLUS_BTN_POSITION_X;

    return plusBtn;
  }

  private initInput(): PIXI.Sprite {
    const input = new PIXI.Sprite(PIXI.Texture.from(ResourceTypes.buyFeatureInput));
    input.y = FEATURE_POPUP_INPUT_POSITION_Y;
    input.x = FEATURE_POPUP_INPUT_POSITION_X;
    input.anchor.set(0.5, 0.5);

    return input;
  }

  private initBetValue(): PIXI.Text {
    const betValue = new PIXI.Text(
      `${formatNumber(this.currency, normalizeCoins(this.getBetValue()), showCurrency(this.currency))}`,
      betValueStyle as Partial<PIXI.ITextStyle>,
    );
    betValue.y = FEATURE_POPUP_BET_VALUE_POSITION_Y;
    betValue.x = FEATURE_POPUP_BET_VALUE_POSITION_X;
    betValue.anchor.set(0.5, 0);

    return betValue;
  }

  private initAngelButton(): FeatureButton {
    const angel = new FeatureButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.angel),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.angelHover),
      },
      [SpriteButtonState.ACTIVE]: {
        texture: PIXI.Texture.from(ResourceTypes.angelActive),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleAngelFeature.bind(this),
      onTouchStart: this.handleAngelFeature.bind(this),
    });
    angel.y = FEATURE_ANGEL_BTN_Y;
    angel.x = isMobile ? MOBILE_FEATURE_ANGEL_BTN_X : FEATURE_ANGEL_BTN_X;
    angel.turnOn();
    return angel;
  }

  private initDevilButton(): FeatureButton {
    const devil = new FeatureButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.devil),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.devilHover),
      },
      [SpriteButtonState.ACTIVE]: {
        texture: PIXI.Texture.from(ResourceTypes.devilActive),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleDevilFeature.bind(this),
      onTouchStart: this.handleDevilFeature.bind(this),
    });
    devil.y = FEATURE_DEVIL_BTN_Y;
    devil.x = isMobile ? MOBILE_FEATURE_DEVIL_BTN_X : FEATURE_DEVIL_BTN_X;
    return devil;
  }

  private initAngelAndDevilButton(): FeatureButton {
    const angelAndDevil = new FeatureButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.angelAndDevil),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.angelAndDevilHover),
      },
      [SpriteButtonState.ACTIVE]: {
        texture: PIXI.Texture.from(ResourceTypes.angelAndDevilActive),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleAngelAndDevilFeature.bind(this),
      onTouchStart: this.handleAngelAndDevilFeature.bind(this),
    });
    angelAndDevil.y = FEATURE_ANGEL_AND_DEVIL_BTN_Y;
    angelAndDevil.x = isMobile ? MOBILE_FEATURE_ANGEL_AND_DEVIL_BTN_X : FEATURE_ANGEL_AND_DEVIL_BTN_X;
    return angelAndDevil;
  }

  private initCancelBtn(): SpriteButton {
    const cancelBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureCancelBtnDisable),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.onCancel.bind(this),
      onTouchStart: this.onCancel.bind(this),
    });
    cancelBtn.y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
    cancelBtn.x = isMobile ? MOBILE_FEATURE_POPUP_CANCEL_BTN_POSITION_X : FEATURE_POPUP_CANCEL_BTN_POSITION_X;
    return cancelBtn;
  }

  private onCancel() {
    AudioHowl.play({ type: ISongs.SFX_UI_Close });
    this.visible = false;
    setIsBuyFeaturePopupOpened(false);
    eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP);
    eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
  }

  private initOkBtn(): SpriteButton {
    const okBtn = new SpriteButton({
      [SpriteButtonState.DEFAULT]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtn),
      },
      [SpriteButtonState.HOVER]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnHover),
      },
      [SpriteButtonState.PRESSED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnPressed),
      },
      [SpriteButtonState.DISABLED]: {
        texture: PIXI.Texture.from(ResourceTypes.buyFeatureOkBtnDisable),
      },
      onHover: () => AudioHowl.play({ type: ISongs.SFX_UI_Hover }),
      onClick: this.handleClickOk.bind(this),
      onTouchStart: this.handleClickOk.bind(this),
    });
    okBtn.y = FEATURE_POPUP_OK_BTN_POSITION_Y;
    okBtn.x = FEATURE_POPUP_OK_BTN_POSITION_X;

    return okBtn;
  }

  private getBetAmount = (betAmount: number): number => {
    return (
      _.findIndex(this.betSettings!.bets, (bet) => {
        return bet === betAmount / this.coinMultiplier;
      }) + 1
    );
  };

  private handleMinus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1] > this.betSettings!.minBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount--;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private handlePlus = (): void => {
    if (this.betSettings.bets[this.betAmount - 1] < this.betSettings!.maxBet) {
      // eslint-disable-next-line no-plusplus
      this.betAmount++;
      setCoinAmount(this.betSettings.bets[this.betAmount - 1]);
      this.updateBets();
      this.handleDisable();
      setBetAmount(setCoinAmount() * this.coinMultiplier);
      AudioHowl.play({ type: ISongs.SFX_UI_BetChange });
    }
  };

  private changeTextStyles(featureState: FeatureState): void {
    this.angelLabelText.text.style =
      featureState === FeatureState.ANGEL ? buyFeatureActiveTextStyle : buyFeatureDisableTextStyle;
    this.devilLabelText.text.style =
      featureState === FeatureState.DEVIL ? buyFeatureActiveTextStyle : buyFeatureDisableTextStyle;
    this.angelAndDevilLabelText.text.style =
      featureState === FeatureState.ANGEL_AND_DEVIL ? buyFeatureActiveTextStyle : buyFeatureDisableTextStyle;
    this.angelTotalCostTextAmount.style =
      featureState === FeatureState.ANGEL ? totalCostActiveTextStyle : totalCostDisableTextStyle;
    this.devilTotalCostTextAmount.style =
      featureState === FeatureState.DEVIL ? totalCostActiveTextStyle : totalCostDisableTextStyle;
    this.angelAndDevilTotalCostTextAmount.style =
      featureState === FeatureState.ANGEL_AND_DEVIL ? totalCostActiveTextStyle : totalCostDisableTextStyle;
  }

  private handleAngelFeature(): void {
    this.angelFeatureButton.turnOn();
    this.devilFeatureButton.turnOff();
    this.angelAndDevilFeatureButton.turnOff();
    this.changeTextStyles(FeatureState.ANGEL);
    this.updateBets();
    this.changeFeatureState(FeatureState.ANGEL);
  }

  private handleDevilFeature(): void {
    this.angelFeatureButton.turnOff();
    this.devilFeatureButton.turnOn();
    this.angelAndDevilFeatureButton.turnOff();
    this.changeTextStyles(FeatureState.DEVIL);
    this.updateBets();
    this.changeFeatureState(FeatureState.DEVIL);
  }

  private handleAngelAndDevilFeature(): void {
    this.angelFeatureButton.turnOff();
    this.devilFeatureButton.turnOff();
    this.angelAndDevilFeatureButton.turnOn();
    this.changeTextStyles(FeatureState.ANGEL_AND_DEVIL);
    this.updateBets();
    this.changeFeatureState(FeatureState.ANGEL_AND_DEVIL);
  }

  private changeFeatureState(state: FeatureState): void {
    this.featureState = state;
    if (state === FeatureState.ANGEL)
      this.featureBtnBg.x = isMobile ? MOBILE_FEATURE_FORM_ANGEL_BTN_X : FEATURE_ANGEL_BTN_X;
    if (state === FeatureState.DEVIL)
      this.featureBtnBg.x = isMobile ? MOBILE_FEATURE_FORM_DEVIL_BTN_X : FEATURE_DEVIL_BTN_X;
    if (state === FeatureState.ANGEL_AND_DEVIL)
      this.featureBtnBg.x = isMobile ? MOBILE_FEATURE_ANGEL_AND_DEVIL_BTN_X : FEATURE_ANGEL_AND_DEVIL_BTN_X;
    AudioHowl.play({ type: ISongs.SFX_UI_General });
    this.handleDisable();
  }

  private updateBets(): void {
    this.angelTotalCostTextAmount.text = this.getTotalCost(FeatureState.ANGEL);
    this.devilTotalCostTextAmount.text = this.getTotalCost(FeatureState.DEVIL);
    this.angelAndDevilTotalCostTextAmount.text = this.getTotalCost(FeatureState.ANGEL_AND_DEVIL);
    this.betValue.text = `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue()),
      showCurrency(this.currency),
    )}`;
  }

  private getTotalCost = (featureState: FeatureState): string => {
    return `${formatNumber(
      this.currency,
      normalizeCoins(this.getBetValue() * this.getCoinAmount(featureState)),
      showCurrency(this.currency),
    )}`;
  };

  private getBetValue = (): number => {
    return this.coinMultiplier * (this.betSettings!.bets[this.betAmount - 1] || 1);
  };

  private getCoinAmount = (featureState: FeatureState): number => {
    const bonuses = setBonuses();
    const bonusId = getBonusIdByFeature(featureState);
    const bonus = _.chain(bonuses)
      .filter((bonus) => bonus.type === FeatureTypes.SPECIAL_ROUND && bonus.id === bonusId)
      .get(0, {})
      .value() as IBonus;

    return bonus.coinAmount;
  };

  private handleClickOk = (): void => {
    AudioHowl.play({ type: ISongs.SFX_UI_General });
    if (!this.isNoFunds) {
      this.visible = false;
      eventManager.emit(
        EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP,
        this.getTotalCost(this.featureState),
        this.betSettings.bets[this.betAmount],
        this.featureState,
      );
      eventManager.emit(EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP_BG);
      eventManager.emit(EventTypes.CLOSE_BUY_FEATURE_POPUP_BG);
    }
  };

  private handleDisable = (): void => {
    const bet = this.betSettings.bets[this.betAmount - 1];
    this.isNoFunds = this.balance < normalizeCoins(this.getBetValue() * this.getCoinAmount(this.featureState));
    if (this.isNoFunds) {
      this.okBtn.disable();
    } else {
      this.okBtn.enable();
    }

    if (bet === this.betSettings!.minBet) {
      this.minusBtn.disable();
    } else {
      this.minusBtn.enable();
    }

    if (bet === this.betSettings!.maxBet) {
      this.plusBtn.disable();
    } else {
      this.plusBtn.enable();
    }
  };

  private closeAllAnimationsInSlot() {
    eventManager.emit(EventTypes.SET_EPIC_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_BIG_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_MEGA_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.SET_GREAT_WIN_VISIBILITY, false);
    eventManager.emit(EventTypes.HIDE_WIN_COUNT_UP_MESSAGE);
  }
}

export default BuyFeaturePopup;
