import * as PIXI from 'pixi.js';

import { SpriteButton, SpriteButtonProps, SpriteButtonState } from './spriteButton';

export interface FeatureButtonProps extends SpriteButtonProps {
  [SpriteButtonState.ACTIVE]?: {
    texture: PIXI.Texture;
  };
  isActive?: boolean;
}
export class FeatureButton extends SpriteButton {
  private isActive = false;

  constructor(props: FeatureButtonProps) {
    super(props);
    this.isActive = !!props.isActive;
    this.state = this.isActive ? SpriteButtonState.ACTIVE : SpriteButtonState.DEFAULT;
    this.textures[SpriteButtonState.ACTIVE] = props[SpriteButtonState.ACTIVE]
      ? props[SpriteButtonState.ACTIVE]!.texture
      : props[SpriteButtonState.DEFAULT].texture;
  }

  public turnOn(): void {
    this.isActive = true;
    this.changeState(SpriteButtonState.ACTIVE);
  }

  public turnOff(): void {
    this.isActive = false;
    this.changeState(SpriteButtonState.DEFAULT);
  }

  protected onMouseOverCallback(): void {
    if (!this.isActive) super.onMouseOverCallback();
  }

  protected onClickCallback(): void {
    if (this.state === SpriteButtonState.DISABLED) return;
    if (this.onClick) {
      this.onClick();
    }
  }

  protected onMouseOutCallback(): void {
    if (this.isActive) {
      this.changeState(SpriteButtonState.ACTIVE);
    } else {
      this.changeState(SpriteButtonState.DEFAULT);
    }
  }

  protected onMouseDownCallback(): void {}
}
